import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
// store import
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
import { ChatDispatchers } from 'src/app/store/chats/chat.dispatchers';
import { ProfileDispatchers } from 'src/app/store/profile/profile.dispatchers';
import { MessageDispatchers } from 'src/app/store/messages/message.dispatchers';
import { MainChatDispatchers } from 'src/app/store/mainChats/mainChat.dispatchers';
import { CHANNELS, CHATS, ID_LOCAL_STORAGE_KEY, INDIVIDUAL, MessageMediaStatus, MessageTypes } from 'src/models/constants';
import { StorageService } from './storage.service';
import { AuthDispatchers } from '../store/auth/auth.dispatchers';
import * as i0 from "@angular/core";
import * as i1 from "../store/root-store.selectors";
import * as i2 from "../store/messages/message.dispatchers";
import * as i3 from "../store/ui/ui.dispatchers";
import * as i4 from "../store/mainChats/mainChat.dispatchers";
import * as i5 from "../store/profile/profile.dispatchers";
import * as i6 from "../store/chats/chat.dispatchers";
import * as i7 from "./storage.service";
import * as i8 from "../store/auth/auth.dispatchers";
import * as i9 from "@angular/platform-browser";
export class UiService {
    constructor(_selectors, _messageDispatchers, _uiDispatchers, _mainChatDispatchers, _profileDispatchers, _chatDispatchers, _storageService, _authDispatchers, _titleService) {
        this._selectors = _selectors;
        this._messageDispatchers = _messageDispatchers;
        this._uiDispatchers = _uiDispatchers;
        this._mainChatDispatchers = _mainChatDispatchers;
        this._profileDispatchers = _profileDispatchers;
        this._chatDispatchers = _chatDispatchers;
        this._storageService = _storageService;
        this._authDispatchers = _authDispatchers;
        this._titleService = _titleService;
        this._replyRcvd = new BehaviorSubject(null);
        this.replyRcvd$ = this._replyRcvd.asObservable();
        this._historyMsgRcvd = new BehaviorSubject(null);
        this.historyMsgRcvd$ = this._historyMsgRcvd.asObservable();
        this._msgRcvd = new BehaviorSubject(false);
        this.msgRcvd$ = this._msgRcvd.asObservable();
        this._scrollToUp = new BehaviorSubject(false);
        this.scrollToUp$ = this._scrollToUp.asObservable();
        this._scrollToBottom = new BehaviorSubject(false);
        this.scrollToBottom$ = this._scrollToBottom.asObservable();
        this._previewMessageType = new BehaviorSubject(null);
        this.previewMessageType$ = this._previewMessageType.asObservable();
        this._previewFiles = new BehaviorSubject(null);
        this.previewFiles$ = this._previewFiles.asObservable();
        this._previewMediaMetadata = new BehaviorSubject({});
        this.previewMediaMetadata$ = this._previewMediaMetadata.asObservable();
        this._previewMediaError = new BehaviorSubject(false);
        this.previewMediaError$ = this._previewMediaError.asObservable();
        this._openGroupMenuPoll = new BehaviorSubject(false);
        this.openGroupMenuPoll$ = this._openGroupMenuPoll.asObservable();
        this._recordAdd = new Subject();
        this.recordAdd$ = this._recordAdd.asObservable();
        this.masonryComponent = null;
        // UI variables
        this.showCompose = false;
        this.showComposeEdditor = false;
        this.isComposeToGroup = true;
        this._messageSendingType = new BehaviorSubject(null);
        this.messageSendingType$ = this._messageSendingType.asObservable();
        this.notifyCount = 0;
        this.msgIdsViewed = [];
    }
    // tslint:disable-next-line:contextual-lifecycle
    ngOnChanges() {
        if (this._storageService.getRecord('ID')) {
            const logginId = this._authDispatchers.getLoginID();
            this.msgIdsViewed = JSON.parse(this._storageService.getRecord(ID_LOCAL_STORAGE_KEY));
        }
    }
    composeEvent(type, isCompose) {
        this.backFromGroupReplies();
        this._messageSendingType.next(type);
        this.isComposeToGroup = isCompose || false;
        this.showCompose = true;
    }
    composeEventEdditor(type, isCompose) {
        this.backFromGroupReplies();
        this._messageSendingType.next(type);
        this.showComposeEdditor = true;
    }
    closeComposeEvent() {
        this.showCompose = false;
        this.showComposeEdditor = false;
        this.hidePreview();
    }
    replyRcvdInSelectedChatNotify(msg) {
        this._replyRcvd.next(msg);
    }
    historyMsgRcvdInSelectedChatNotify(msg) {
        this._historyMsgRcvd.next(msg);
    }
    msgRcvdInSelectedChatNotify() {
        this._msgRcvd.next(true);
        setTimeout(() => {
            this._msgRcvd.next(false);
        }, 10);
    }
    scrollToUpMethod() {
        this._scrollToUp.next(true);
        setTimeout(() => {
            this._scrollToUp.next(false);
        }, 300);
    }
    scrollToBottomMethod() {
        this._scrollToBottom.next(true);
        setTimeout(() => {
            this._scrollToBottom.next(false);
        }, 300);
    }
    blink(msg, count = 5) {
        if (this.notifyCount === 0) {
            this.prevTitle = this._titleService.getTitle();
        }
        this.notifyCount = this.notifyCount + 1;
        msg = this.notifyCount + ' ' + msg;
        const step = () => {
            const newTitle = this._titleService.getTitle() === this.prevTitle ? msg : this.prevTitle;
            this._titleService.setTitle(newTitle);
            if (document.hasFocus() || count === 0) {
                this.notifyCount = 0;
                this._titleService.setTitle(this.prevTitle);
            }
            else {
                this.timeout = setTimeout(step.bind(this), 1000);
            }
        };
        clearTimeout(this.timeout);
        step();
    }
    setTitle() {
        // TODO: set title for web app
    }
    selectTab(type) {
        if (CHANNELS === type) {
            if (this.channalsTab) {
                this.channalsTab.nativeElement.click();
            }
            else {
                // TODO: for merchant select message
            }
        }
        else if (CHATS === type) {
            if (this.chatsTab) {
                this.chatsTab.nativeElement.click();
            }
        }
    }
    /** Profile, MainChat and Chat actions */
    requestHistory(parentMsgID, userID, requestOnce, subChatID) {
        this._mainChatDispatchers.requestHistory(parentMsgID, userID, requestOnce, subChatID);
        if (this.masonryComponent) {
            this.masonryComponent.layout();
        }
    }
    deselectChat(mainChat, lastTypedMsg) {
        if (mainChat.type === INDIVIDUAL) {
            this._profileDispatchers.deselectProfile(mainChat.id, lastTypedMsg);
        }
        else {
            this._chatDispatchers.deselectChat(mainChat.id, lastTypedMsg);
        }
    }
    getMessageCounters(chatID, messageIDs) {
        this._chatDispatchers.requestChatCounters(chatID, messageIDs);
    }
    /** Message Actions */
    resetNumberOfNotViewedMessages(mainChatID) {
        this._mainChatDispatchers.resetNumberOfNotViewedMessages(mainChatID);
    }
    get effectiveSelectedSubGroupMessage$() {
        return this._selectors.getEffectiveSelectedSubGroupMessage$();
    }
    resetNumberOfUnreadReplies(messageID) {
        this._messageDispatchers.resetNumberOfUnreadReplies(messageID);
    }
    /************************************************************************************/
    sendTextMessage(mainChat, parentMessage, userId, text, misc) {
        this._messageDispatchers.sendTextMessage(mainChat, parentMessage, userId, text, misc);
    }
    sendInstantUrl(mainChat, parentMessage, userId, instantArticle, misc) {
        this._messageDispatchers.sendInstantUrlMessage(mainChat, parentMessage, userId, instantArticle, misc);
    }
    sendMediaMessage(mainChat, parentMessage, userId, file, mediaType, previewMetadata, blobUrl, misc) {
        this._messageDispatchers.sendMediaMessage(mainChat, parentMessage, userId, file, mediaType, previewMetadata, blobUrl, misc);
    }
    sendCalendarMessage(mainChat, parentMessage, userId, calendar, misc) {
        this._messageDispatchers.sendCalendarMessage(mainChat, parentMessage, userId, calendar, misc);
    }
    /*****************************************************************************************************/
    getBlobUrl(selectedFiles) {
        this._messageDispatchers.getBlobUrl(selectedFiles);
    }
    cancelMediaOperation(message) {
        this._messageDispatchers.cancelMediaOperation(message);
    }
    retryMediaOperation(message) {
        this._messageDispatchers.retryMediaOperation(message);
    }
    previewLinkMessage(message) {
        this._messageDispatchers.previewLinkMessage(message);
    }
    likePost(msg) {
        if (!msg.message_id) {
            return;
        }
        this._messageDispatchers.likeMessage(msg);
    }
    unlikePost(msg) {
        if (!msg.message_id) {
            return;
        }
        this._messageDispatchers.unlikeMessage(msg);
    }
    sharePost(msg) {
        if (!msg.message_id) {
            return;
        }
        this._messageDispatchers.shareMessage(msg);
    }
    recallMessage(msg) {
        this._messageDispatchers.recallMessage(msg);
    }
    setCounterIfExisting(message_id) {
        this._storageService
            .getPostCounterToCache(message_id)
            .then(res => this._messageDispatchers.setCounterIfExisting(message_id, res));
    }
    viewMessageEvent($event, message) {
        if ($event.visible) {
            // // Message in view port
            if ((message.type === MessageTypes.TEXT ||
                message.type === MessageTypes.ARTICLE) &&
                !message.linkPreviewStatus) {
                this._messageDispatchers.previewLinkMessage(message);
            }
            if (!message.isViewed) {
                this.viewMessage(message, this.msgIdsViewed);
            }
            if (!message.localMedia &&
                !message.mediaStatus &&
                message.type !== MessageTypes.VIDEO &&
                message.type !== MessageTypes.AUDIO &&
                message.message_id &&
                message.media_id) {
                this.downloadMediaFile(message);
            }
            if ((message.type === MessageTypes.VIDEO ||
                message.type === MessageTypes.AUDIO) &&
                (message.mediaStatus !== MessageMediaStatus.DOWNLOADED &&
                    message.mediaStatus !== MessageMediaStatus.UPLOADED)) {
                this._messageDispatchers.cancelDownloadMediaMessage(message);
            }
        }
        else {
            // Message Out Of view port
            if (message.mediaStatus === MessageMediaStatus.DOWNLOADING &&
                message.loadingProgress &&
                message.loadingProgress < 40) {
                this._messageDispatchers.cancelDownloadMediaMessage(message);
            }
        }
    }
    downloadMediaFile(message) {
        this._messageDispatchers.downloadMediaMessage(message);
    }
    messageViewed(mainChat) {
        this._mainChatDispatchers.decrementNumberOfNotViewedMessages(mainChat);
    }
    viewMessage(msg, msgIdsViewed) {
        if (!msg.isViewed && !msg.loggedInIsSender && !msg.reply_to_message_id) {
            const mainChat = {};
            if (!msg.group_id) {
                mainChat.id = msg.sender_id;
            }
            else {
                mainChat.id = msg.group_id;
            }
            this._mainChatDispatchers.decrementNumberOfNotViewedMessages(mainChat);
        }
        this._messageDispatchers.messageIsViewed(msg, msgIdsViewed);
    }
    getMessageMenuStats(message) {
        this.selectedMessageForStats(message);
        this._messageDispatchers.getMessageMenuStats(message.message_id);
    }
    /** UI Actions */
    selectChat(chat) {
        this._uiDispatchers.hideDragDropScreen();
        this._uiDispatchers.hidePreview();
        this._uiDispatchers.chatSelected(chat);
    }
    selectedMessage(parentMessage) {
        this._uiDispatchers.messageSelected(parentMessage);
        this._uiDispatchers.groupRepliesSelected();
    }
    selectSubGroupMessage(parentMessage) {
        this._uiDispatchers.subParentMessageSelected(parentMessage);
    }
    selectedMessageForStats(message) {
        this._uiDispatchers.messageSelectedForStats(message);
    }
    backFromGroupReplies() {
        this._uiDispatchers.backFromGroupReplies();
        this._uiDispatchers.hidePreview();
        if (this.masonryComponent) {
            this.masonryComponent.layout();
        }
    }
    backFromOneToOneReply() {
        this._uiDispatchers.backFromOneToOneReply();
        this._uiDispatchers.hidePreview();
    }
    selectChannelPost(parentMessage) {
        this._uiDispatchers.selectChannelPost(parentMessage);
    }
    selectSubChannelPost(parentMessage) {
        this._uiDispatchers.selectSubChannelPost(parentMessage);
    }
    selectOneToOneReply(userID) {
        this._uiDispatchers.selectOneToOneReply(userID);
    }
    subUserSelected(userID) {
        this._uiDispatchers.subUserSelected(userID);
    }
    subUserDeselected() {
        this._uiDispatchers.subUserDeselected();
    }
    selectTalkToAdmin() {
        this._uiDispatchers.selectTalkToAdmin();
    }
    toggleHelpWindow(status) {
        this._uiDispatchers.toggleHelpWindow(status);
    }
    setPostRepliesPageNumber(pageNumber) {
        this._uiDispatchers.setPostRepliesPageNumber(pageNumber);
    }
    setMessagesPageNumber(pageNumber) {
        this._uiDispatchers.setMessagesPageNumber(pageNumber);
    }
    setPostsPageNumber(pageNumber) {
        this._uiDispatchers.setPostsPageNumber(pageNumber);
    }
    setSubPostsPageNumber(pageNumber) {
        this._uiDispatchers.setSubPostsPageNumber(pageNumber);
    }
    setSubPostRepliesPageNumber(pageNumber) {
        this._uiDispatchers.setSubPostRepliesPageNumber(pageNumber);
    }
    setSubGroupMessagesPageNumber(pageNumber) {
        this._uiDispatchers.setSubGroupMessagesPageNumber(pageNumber);
    }
    showPreview() {
        this._uiDispatchers.showPreview();
    }
    hidePreview() {
        this._uiDispatchers.hidePreview();
    }
    showMediaScreen(message) {
        this._uiDispatchers.showMediaScreen(message);
    }
    hideMediaScreen() {
        this._uiDispatchers.hideMediaScreen();
    }
    showPopupMessage(popupMessage, title) {
        this._uiDispatchers.showPopup(popupMessage, title);
    }
    showErrorToast(massage) {
        this._uiDispatchers.showErrorPopup(massage);
    }
    hidePopup() {
        this._uiDispatchers.hidePopup();
    }
    hideErrorSnackbar() {
        this._uiDispatchers.hideErrorSnackbar();
    }
    requestChatDetails(chatId) {
        this._chatDispatchers.requestChatDetails(chatId);
    }
    /* Selectors */
    getProfileByID$(userID) {
        return this._selectors.getProfileByID$(userID);
    }
    getChatMessages$() {
        return this._selectors.getMessagesToView$();
    }
    getSubChatMessages$() {
        return this._selectors.getSubMessagesToView$();
    }
    getSubChatMessages1$() {
        return this._selectors.getSubMessagesToView1$();
    }
    getChatMessagesByParams$(selectedMessageId, selectedUserId) {
        return this._selectors.getMessagesToViewByParams$(selectedMessageId, selectedUserId);
    }
    getChatByID$(chatID) {
        return this._selectors.getChatByID$(chatID);
    }
    getChatGroupById$(chatID) {
        return this._selectors.getChatGroupById$(chatID);
    }
    getMessageStats$(message_id) {
        return this._selectors.getMessageStats$(message_id);
    }
    get subChats$() {
        return this._selectors.subChats$;
    }
    get profilesWhoRepliedToChannelPost$() {
        return this._selectors.getProfilesWhoRepliedToChannelPost$();
    }
    get profilesWhoRepliedToSubChannelPost$() {
        return this._selectors.getProfilesWhoRepliedToSubChannelPost$();
    }
    get profilesRepliedToAdmin$() {
        return this._selectors.getProfilesWhoRepliedToAdmin$();
    }
    get myPublicProfile$() {
        return this._selectors
            .myProfiles$()
            .pipe(map(items => items.find(profile => profile.relation === 'Other')));
    }
    get selectedUser$() {
        return this._selectors.getSelectedUser$();
    }
    get effectiveSelectedChat$() {
        return this._selectors.getEffectiveSelectedChat$();
    }
    get effectiveSubSelectedChat$() {
        return this._selectors.getEffectiveSubSelectedChat$();
    }
    get numberOfNotViewedMessagesOfSelectedUser$() {
        return this._selectors.getNumberOfNotViewedMessagesOfSelectedUser$();
    }
    get channelMessagesToView$() {
        return this._selectors.getChannelMessagesToView$();
    }
    get subChannelMessagesToView$() {
        return this._selectors.getSubChannelMessagesToView$();
    }
    get uiCollection$() {
        return this._selectors.uiCollection$;
    }
    get chatMediaMessages$() {
        return this._selectors.getMediaMessagesInChat$();
    }
    get effectiveMediaMessage$() {
        return this._selectors.getEffectiveMediaMessage$();
    }
    get selectedChat$() {
        return this._selectors.getSelectedChat$();
    }
    get selectedSubChat$() {
        return this._selectors.getSelectedSubChat$();
    }
    get chatLable$() {
        return this._selectors.getChatLabel$();
    }
    getInlineMessageCallBack(buttonData) {
        this._messageDispatchers.getInlineMessageCallBack(buttonData);
    }
    get privileges$() {
        return this._selectors.privileges$;
    }
    togglePopupUpgradePlanModule(moduleID) {
        this._uiDispatchers.togglePopupUpgradePlanModule(moduleID);
    }
    addToastMessage(msg, status) {
        this._uiDispatchers.addToastMessage(msg, status);
    }
    removeToastMessage(index) {
        this._uiDispatchers.removeToastMessage(index);
    }
    toggleExpiredPopupUI(status) {
        this._uiDispatchers.toggleExpiredPopup(status);
    }
    checkPrivillage$(priv) {
        return this._selectors.checkPrivileges$(priv);
    }
    selectSubChat(chat) {
        this._uiDispatchers.hideDragDropScreen();
        this._uiDispatchers.hidePreview();
        this._uiDispatchers.subChatSelected(chat);
    }
    listAwardMessages() {
        this._messageDispatchers.listAwardMessages();
    }
    setTemplate(id, type, value) {
        this._uiDispatchers.setChatToTemplate(id, type, value);
    }
    setTemplateMessage(channelId, messageType, messageId, message) {
        this._uiDispatchers.setTemplateMessage(channelId, messageType, messageId, message);
    }
    enableSetTemplateBtn(value) {
        this._uiDispatchers.enableSetTemplateBtn(value);
    }
    get getEnableSetTemplateBtn$() {
        return this._selectors.getEnableSetTemplateBtn$;
    }
    setChatLabel(chatLabel) {
        this._uiDispatchers.setChatLabels(chatLabel);
    }
    listSelected(list) {
        this._uiDispatchers.listSelected(list);
    }
    get getChatUsersList$() {
        return this._selectors.getChatUsersList$;
    }
}
UiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UiService_Factory() { return new UiService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.MessageDispatchers), i0.ɵɵinject(i3.UIDispatchers), i0.ɵɵinject(i4.MainChatDispatchers), i0.ɵɵinject(i5.ProfileDispatchers), i0.ɵɵinject(i6.ChatDispatchers), i0.ɵɵinject(i7.StorageService), i0.ɵɵinject(i8.AuthDispatchers), i0.ɵɵinject(i9.Title)); }, token: UiService, providedIn: "root" });
