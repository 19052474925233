<!-- Start: Channels Replaies -->
<div
  *ngIf="uiService.uiCollection$ | async as uiCollection"
  class="detail-container"
>
  <!-- Header Section -->
  <app-message-post-operations
    [isNested]="uiCollection?.selectedUserId"
    [message]="uiCollection?.selectedMessage"
    [parentId]="uiCollection?.selectedMessage?.message_id"
    (backNotify)="backFromOneToOneReply()"
    (closeNotify)="backFromGroupReplies()"
  >
  </app-message-post-operations>

  <!-- Body section -->
  <ng-container
    *ngIf="
      (uiService.profilesWhoRepliedToChannelPost$ | async)?.channelMemberList
        .length > 0
    "
    >hello</ng-container
  >
  <ng-template [ngIf]="!uiCollection?.showPreview">
    <app-channel-replies
      *ngIf="!uiCollection?.selectedUserId"
      class="body"
      [message]="dashboardService.effectiveParentMessage$ | async"
      [chat]="uiCollection?.selectedChat"
      [hideRepliesSec]="false"
      [isNotifications]="true"
    >
    </app-channel-replies>

    <app-member-replies
      *ngIf="uiCollection?.selectedUserId"
      class="body"
      [selectedMessageId]="
        (dashboardService.effectiveParentMessage$ | async)?.message_id
      "
      [selectedUserId]="uiCollection?.selectedUserId"
      [selectedChatId]="uiCollection?.selectedChat?.id"
    >
    </app-member-replies>
    <!-- Footer section -->
    <app-chat-box
      *ngIf="uiCollection?.selectedUserId"
      [selectedChat]="uiCollection?.selectedChat"
      [selectedMessage]="uiCollection?.selectedMessage"
      [selectedUserId]="uiCollection?.selectedUserId"
      [groupReplies]="uiCollection?.groupReplies"
    >
    </app-chat-box>
  </ng-template>

  <!-- Preview Media before send for user -->
  <app-preview-media
    *ngIf="uiCollection?.showPreview && uiCollection?.selectedUserId"
    class="body"
    #replyMediaPreview
    [themeColor]="themeColor"
    [selectedChat]="uiCollection?.selectedChat"
    [selectedMessage]="uiCollection?.selectedMessage"
    [selectedUserId]="uiCollection?.selectedUserId"
    [mediaMetadata]="uiService.previewMediaMetadata$ | async"
    [mediaError]="uiService.previewMediaError$ | async"
    [files]="uiService.previewFiles$ | async"
    [messageType]="uiService.previewMessageType$ | async"
    [isErrorMessageShown]="uiCollection?.showPopup"
    (sendMediaNotfiy)="
      sendReplyMediaPreview(replyMediaPreview?.getMediaToSend())
    "
  >
    <app-border-btn
      submit
      (btnClicked)="sendReplyMediaPreview(replyMediaPreview?.getMediaToSend())"
      footer-submit
    >
      Send
    </app-border-btn>
  </app-preview-media>
</div>
<!-- End: Channels Replaies-->
