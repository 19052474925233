import * as tslib_1 from "tslib";
import { HttpEventType } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { withLatestFrom, distinctUntilChanged, map } from 'rxjs/operators';
import { MessageMiddleware } from './message.middleware';
import { NotifyService } from 'src/app/core/notify.service';
import { FileService } from 'src/app/core/file.service';
import { HelperService } from 'src/app/core/helper.service';
import { UiService } from 'src/app/core/ui.service';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { MessageGateway } from 'src/app/network/gateway/message.gateway';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { DownloadGateway } from 'src/app/network/gateway/download.gateway';
import { LinkPreviewGateway } from 'src/app/network/gateway/link-preview.gateway';
import { UIDispatchers } from './../ui/ui.dispatchers';
import { MessageDispatchers } from './message.dispatchers';
import { ChatMemberDispatchers } from '../chatMembers/chatMember.dispatchers';
import { RepliesEOPDispatchers } from '../repliesEOP/repliesEOP.dispatchers';
import { MainChatDispatchers } from '../mainChats/mainChat.dispatchers';
import { AuthDispatchers } from '../auth/auth.dispatchers';
import * as MessageActions from './message.actions';
import * as TagMethods from 'src/models/ITag';
import * as MenuCallBackStatsMethods from 'src/models/MenuCallBackStats';
import * as PostMethods from 'src/models/PostCounters';
import * as MessagesMethods from 'src/models/IMessage';
import { INDIVIDUAL, GROUP, CHANNEL, MessageSendingStatus, MessageStatuses, MessageActionTypes, PROJECT_NAME, MERCHANT_PROJECT, MessageTypes, NO_FILE_NAME, NOT_SUPPORTED_FILE_TYPE, FILE_SIZE_EXCEEDED, MessageMediaStatus, MessageLinkPreviewStatus, SERVER_PAGE_SIZE } from 'src/models/constants';
import { PrivilegesName } from 'src/models/privileges';
import { ChatDispatchers } from '../chats/chat.dispatchers';
import * as isEqual from 'lodash.isequal';
import { StorageService } from 'src/app/core/storage.service';
import { FILE_SIZE_ZERO_LENGTH } from './../../../models/constants';
const groupTimer = [];
export class MessageEffects {
    constructor(actions$, _uploadGateway, _downloadGateway, _messageGateway, _socketGateway, _linkPreviewGateway, _uiService, _fileService, _helperService, _notfiyservice, _uiDispatchers, _chatDispatchers, _authDispatchers, _messageDispatchers, _mainChatDispatchers, _chatMemberDispatchers, _repliesEOPDispatchers, _storageService, _store) {
        this.actions$ = actions$;
        this._uploadGateway = _uploadGateway;
        this._downloadGateway = _downloadGateway;
        this._messageGateway = _messageGateway;
        this._socketGateway = _socketGateway;
        this._linkPreviewGateway = _linkPreviewGateway;
        this._uiService = _uiService;
        this._fileService = _fileService;
        this._helperService = _helperService;
        this._notfiyservice = _notfiyservice;
        this._uiDispatchers = _uiDispatchers;
        this._chatDispatchers = _chatDispatchers;
        this._authDispatchers = _authDispatchers;
        this._messageDispatchers = _messageDispatchers;
        this._mainChatDispatchers = _mainChatDispatchers;
        this._chatMemberDispatchers = _chatMemberDispatchers;
        this._repliesEOPDispatchers = _repliesEOPDispatchers;
        this._storageService = _storageService;
        this._store = _store;
        this.uploadSubscriptions = [];
        this._previewArrayBuffer = null;
        this.getLastPostsHistoryEffect = this.actions$.pipe(ofType(MessageActions.GET_LAST_POSTS_HISTORY), withLatestFrom(this._store
            .select(s => s.uiReducer.privileges)
            .pipe(distinctUntilChanged(isEqual))), map(([n, privileges]) => {
            if (privileges.indexOf(PrivilegesName.listmessage) > -1) {
                this.getLastPostsHistory();
            }
        }));
        this.getLastRepliesHistoryEffect = this.actions$.pipe(ofType(MessageActions.GET_LAST_REPLIES_HISTORY), withLatestFrom(this._store
            .select(s => s.uiReducer.privileges)
            .pipe(distinctUntilChanged(isEqual))), map(([n, privileges]) => {
            if (privileges.indexOf(PrivilegesName.listmessage) > -1) {
                this.getLastRepliesHistory();
            }
        }));
        this.listScheduleMessages = this.actions$.pipe(ofType(MessageActions.LIST_SCHEDULE_MESSAGES), withLatestFrom(this._store
            .select(s => s.uiReducer.privileges)
            .pipe(distinctUntilChanged(isEqual))), map(([n, privileges]) => {
            if (privileges.indexOf(PrivilegesName.listmessage) > -1) {
                this.getScheduleMessagesHistory();
            }
        }));
        this.listAwardMessages = this.actions$.pipe(ofType(MessageActions.LIST_AWARD_MESSAGES), withLatestFrom(this._store
            .select(s => s.uiReducer.privileges)
            .pipe(distinctUntilChanged(isEqual))), map(([n, privileges]) => {
            if (privileges.indexOf(PrivilegesName.listmessage) > -1) {
                this.getAwardMessagesHistory();
            }
        }));
        this.listTags = this.actions$.pipe(ofType(MessageActions.LIST_TAGS), withLatestFrom(this._store
            .select(s => s.uiReducer.privileges)
            .pipe(distinctUntilChanged(isEqual))), map(([n, privileges]) => {
            const action = n;
            if (privileges.indexOf(PrivilegesName.listtag) > -1) {
                this._socketGateway.sendSocketMessage(new TagMethods.GetTags(action.chat_ID));
            }
        }));
        this.initRcvdMsg = this.actions$.pipe(ofType(MessageActions.INIT_RECEIVED_MESSAGE), withLatestFrom(this._store
            .select(state => state.messageReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.uiReducer.selectedSubChat)
            .pipe(distinctUntilChanged(isEqual))), map(([val, messagesState, selectedSubChat]) => {
            const action = val;
            if (action.payload.message.reply_to_message_id &&
                !this.isMessageExisting({
                    message_id: action.payload.message.reply_to_message_id
                }, messagesState) &&
                (!selectedSubChat || !selectedSubChat.onboard)) {
                if (PROJECT_NAME === MERCHANT_PROJECT) {
                    this._socketGateway.sendSocketMessage(new MessagesMethods.GetMessagesHistoryFromBusinessServer(action.payload.message.reply_to_message_id));
                }
                else {
                    // Do nothing as we do not have the parent message of the received message
                    return;
                }
            }
            const loginID = this._authDispatchers.getLoginID();
            const key = action.payload.message.reply_to_message_id +
                '$' +
                action.payload.message.from.id;
            const messagesIds = this._storageService.getRecord(key);
            const msg = MessageMiddleware.initRcvdMessage(action.payload, loginID, JSON.parse(messagesIds));
            // check if message is schedule or award
            if (msg.schedule_date) {
                this._messageDispatchers.receiveScheduleMessage(msg);
                return;
            }
            else if (msg.award) {
                this._messageDispatchers.receiveAwardMessage(msg);
                return;
            }
            else if (msg.welcome) {
                this._messageDispatchers.receiveWelcomeMessage(msg);
                return;
            }
            msg.existedBefore = this.isMessageExisting(msg, messagesState);
            if (!msg.updateStatus ||
                MessageStatuses.NEW === msg.updateStatus ||
                (!msg.existedBefore && MessageStatuses.UPDATED === msg.updateStatus)) {
                msg.actionType = MessageActionTypes.NORMAL;
            }
            else if (msg.existedBefore &&
                MessageStatuses.UPDATED === msg.updateStatus) {
                msg.actionType = MessageActionTypes.UPDATED;
            }
            else if (msg.existedBefore &&
                MessageStatuses.DELETED === msg.updateStatus) {
                msg.actionType = MessageActionTypes.DELETED;
            }
            this._messageDispatchers.receiveMessage(msg);
        }));
        this.messagesSeen = this.actions$.pipe(ofType(MessageActions.MESSAGES_SEEN_RECEIVED), map((action) => {
            for (let i = 0; i < action.message_ids.length; i++) {
                this._messageDispatchers.messageSeen(action.message_ids[i]);
            }
        }));
        this.hidePreview = this.actions$.pipe(ofType("UI_HIDE_PREVIEW" /* UI_HIDE_PREVIEW */), map((action) => {
            this._uiService._previewMediaMetadata.next({});
            this._uiService._previewMediaError.next(false);
            // should add effect in message effects on hide of preview to set this to null
            this._uiService._previewMessageType.next(null);
            this._uiService._previewFiles.next(null);
            this._previewArrayBuffer = null;
        }));
        this.downloadMessageThumbnail = this.actions$.pipe(ofType(MessageActions.DOWNLOAD_MESSAGE_THUMBNAIL), map((action) => {
            const msg = action.payload;
            if (!msg.localThumbnail && msg.thumbnail_id && !msg.thumbnailStatus) {
                msg.thumbnailStatus = MessageMediaStatus.DOWNLOADING;
                this._messageDispatchers.setMessageThumbnailStatus(msg);
                this._downloadGateway
                    .downloadMediaFile(action.payload.thumbnail_id, 'image/png')
                    .then(res => {
                    this._messageDispatchers.setMessageLocalThumbnail(Object.assign({}, action.payload, { localThumbnail: res, thumbnailStatus: MessageMediaStatus.DOWNLOADED }));
                })
                    .catch(err => {
                    msg.localThumbnail = MessageMediaStatus.DOWNLOAD_FAILED;
                    this._messageDispatchers.setMessageThumbnailStatus(msg);
                });
            }
        }));
        // CHAT_MESSAGES_OUT_OF_VIEW_PORT
        this.cancelDownloadOfChatMsgs = this.actions$.pipe(ofType(MessageActions.CHAT_MESSAGES_OUT_OF_VIEW_PORT), withLatestFrom(this._store
            .select(state => state.messageReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, messagesState]) => {
            const action = val;
            const filteredMediaMsgs = messagesState.filter(msg => msg.group_id &&
                ((action.groupID && msg.group_id === action.groupID) ||
                    (!action.groupID && msg.group_type === 101)) &&
                (msg.mediaStatus === MessageMediaStatus.DOWNLOADING &&
                    msg.loadingProgress &&
                    msg.loadingProgress < 40));
            filteredMediaMsgs.forEach(message => this._messageDispatchers.cancelDownloadMediaMessage(message));
        }));
        this.cancelDownloadOfContactMsgs = this.actions$.pipe(ofType(MessageActions.CONTACT_MESSAGES_OUT_OF_VIEW_PORT), withLatestFrom(this._store
            .select(state => state.messageReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, messagesState]) => {
            const action = val;
            const filteredMediaMsgs = messagesState.filter(msg => !msg.group_id &&
                action.id &&
                (action.id === msg.sender_id || action.id === msg.receiver_id) &&
                (msg.mediaStatus === MessageMediaStatus.DOWNLOADING &&
                    msg.loadingProgress &&
                    msg.loadingProgress < 40));
            filteredMediaMsgs.forEach(message => this._messageDispatchers.cancelDownloadMediaMessage(message));
        }));
        this.downloadMessageMedia = this.actions$.pipe(ofType(MessageActions.DOWNLOAD_MEDIA_MESSAGE), map((action) => {
            const localType = this._fileService.getMimeTypeFromMessageType(action.payload.type);
            const msg = action.payload;
            if (!msg.localMedia) {
                msg.mediaStatus = MessageMediaStatus.DOWNLOADING;
                this._messageDispatchers.setMessageMediaStatus(msg);
                this._downloadGateway
                    .downloadMediaFile(action.payload.media_id, localType, false, msg.message_id, this._messageDispatchers)
                    .then(res => {
                    this._messageDispatchers.setMessageLocalMedia(Object.assign({}, action.payload, { localMedia: res, mediaStatus: MessageMediaStatus.DOWNLOADED }));
                })
                    .catch(err => {
                    msg.mediaStatus = MessageMediaStatus.DOWNLOAD_FAILED;
                    this._messageDispatchers.setMessageMediaStatus(msg);
                });
            }
        }));
        this.cancelDownloadMessageMedia = this.actions$.pipe(ofType(MessageActions.CANCEL_DOWNLOAD_MEDIA_MESSAGE), map((action) => {
            const msg = action.payload;
            msg.mediaStatus = MessageMediaStatus.DOWNLOAD_FAILED;
            this._downloadGateway.cancelDownload(msg.message_id);
            this._messageDispatchers.setMessageMediaStatus(msg);
        }));
        this.updateExistingMessage = this.actions$.pipe(ofType(MessageActions.UPDATE_EXISTING_MESSAGE), map((action) => {
            const msg = action.payload;
            if (msg.thumbnail_id) {
                this._messageDispatchers.downloadMessageThumbnail(action.payload);
            }
            if (msg.endOfPage && !msg.reply_to_message_id) {
                const targetChat = {};
                if (!action.payload.group_id) {
                    if (!action.payload.loggedInIsSender) {
                        targetChat.id = action.payload.sender_id;
                    }
                    else {
                        targetChat.id = action.payload.receiver_id;
                    }
                }
                else {
                    targetChat.id = action.payload.group_id;
                }
                this._mainChatDispatchers.updateEndOfPage(targetChat.id, msg.endOfPage);
            }
        }));
        this.messageReceived = this.actions$.pipe(ofType(MessageActions.RECEIVE_MESSAGE), withLatestFrom(this._store
            .select(state => state.mainChatReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.profileReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mainChats, allProfiles]) => {
            const action = val;
            if (action.payload.thumbnail_id) {
                this._messageDispatchers.downloadMessageThumbnail(action.payload);
            }
            // const mainChat: MainChat = {};
            let targetChat = {};
            if (action.payload.group_id) {
                targetChat = mainChats.find(chat => chat.id === action.payload.group_id);
            }
            else {
                if (!action.payload.loggedInIsSender) {
                    targetChat = mainChats.find(chat => chat.id === action.payload.sender_id);
                }
                else {
                    targetChat = mainChats.find(chat => chat.id === action.payload.receiver_id);
                }
            }
            // The following condition means do that if not a history message as the history message for now is considered read and viewed
            if (!action.payload.loggedInIsSender && !action.payload.isViewed) {
                let unreadMsgsCount;
                if (targetChat && !targetChat.selected) {
                    unreadMsgsCount = targetChat.unreadCounter
                        ? targetChat.unreadCounter
                        : 0;
                    this._mainChatDispatchers.updateNumberOfUnreadMessages(targetChat.id, ++unreadMsgsCount, !action.payload.reply_to_message_id ? true : false, false);
                }
                else if (targetChat && targetChat.selected) {
                    if (!action.payload.reply_to_message_id) {
                        this._mainChatDispatchers.incrementNumberOfNotViewedMessages(targetChat);
                    }
                    else {
                        this._mainChatDispatchers.replyReceivedInSelectedChat(action.payload);
                    }
                }
            }
            if (targetChat && targetChat.selected) {
                if (!action.payload.reply_to_message_id) {
                    if (!action.payload.endOfPage) {
                        this._uiService.msgRcvdInSelectedChatNotify();
                    }
                }
                if (action.payload.endOfPage) {
                    this._uiService.historyMsgRcvdInSelectedChatNotify(action.payload);
                }
            }
            if (action.payload && targetChat) {
                const mainChat = Object.assign({}, targetChat);
                if (targetChat.type === GROUP) {
                    if (!action.payload.loggedInIsSender) {
                        const senderProfile = allProfiles.find(prof => prof.user_id === mainChat.id);
                        mainChat.lastSenderIDInGroup = action.payload.sender_id;
                        mainChat.lastSenderNameInGroup = senderProfile
                            ? senderProfile.name
                            : action.payload.sender_name;
                    }
                    else {
                        mainChat.lastSenderIDInGroup = null;
                        mainChat.lastSenderNameInGroup = null;
                    }
                }
                this._mainChatDispatchers.updateLastMessage(mainChat, this._helperService.handleDifferentReplyTypes(action.payload));
                if (action.payload.endOfPage) {
                    if (action.payload.level && action.payload.level === 1) {
                        this._mainChatDispatchers.UpdateEopLevel1(targetChat.id, action.payload.endOfPage);
                    }
                    if (!action.payload.reply_to_message_id) {
                        this._mainChatDispatchers.updateEndOfPage(targetChat.id, action.payload.endOfPage);
                    }
                    else {
                        // this condition should match the condition of group replies and one to one reply in channels
                        if (targetChat.type === GROUP ||
                            (targetChat.type === CHANNEL && !targetChat.isAdmin)) {
                            this._repliesEOPDispatchers.updateFirstDepthEOP(action.payload.reply_to_message_id, action.payload.endOfPage, targetChat.id);
                        }
                        else if (targetChat.type === CHANNEL && targetChat.isAdmin) {
                            if (action.payload.level === 3) {
                                let userID = null;
                                if (action.payload.from_admin) {
                                    userID = action.payload.receiver_id;
                                }
                                else {
                                    userID = action.payload.sender_id;
                                }
                                this._repliesEOPDispatchers.updateSecondDepthEOP(action.payload.reply_to_message_id, action.payload.endOfPage, userID, targetChat.id);
                            }
                            else if (action.payload.level === 2) {
                                this._repliesEOPDispatchers.updateFirstDepthEOP(action.payload.reply_to_message_id, action.payload.endOfPage, targetChat.id);
                            }
                        }
                    }
                }
            }
            if (!action.payload.endOfPage && !action.payload.loggedInIsSender) {
                const date = new Date();
                let timer = 0;
                if (groupTimer[action.payload.sender_id]) {
                    timer = date.getTime() - groupTimer[action.payload.sender_id];
                }
                if (targetChat) {
                    if ((targetChat.type === 'Group' || targetChat.type === 'Channel') &&
                        (timer === 0 || timer >= 40000)) {
                        groupTimer[action.payload.sender_id] = date.getTime();
                        this._notfiyservice.showNotification(targetChat, action);
                    }
                    else if (targetChat.type === 'Individual') {
                        this._notfiyservice.showNotification(targetChat, action);
                    }
                }
                else {
                    this._notfiyservice.showNotification(targetChat, action);
                }
            }
            if (this._uiService.masonryComponent) {
                this._uiService.masonryComponent.reloadItems();
                this._uiService.masonryComponent.layout();
            }
        }));
        // sending message after the store is updated, then update last message , and send message
        this.sendingMsg = this.actions$.pipe(ofType(MessageActions.SENDING_MESSAGE), withLatestFrom(this._store
            .select(state => state.messageReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, messagesState]) => {
            const action = val;
            const mainChat = {};
            if (!action.payload.group_id) {
                mainChat.id = action.payload.receiver_id;
            }
            else {
                mainChat.id = action.payload.group_id;
            }
            const message = messagesState.find(msg => msg.reference === action.payload.reference && !msg.message_id);
            const targetChat = Object.assign({}, mainChat);
            targetChat.lastSenderIDInGroup = null;
            targetChat.lastSenderNameInGroup = null;
            this._mainChatDispatchers.updateLastMessage(targetChat, this._helperService.handleDifferentReplyTypes(message));
            if (this._uiService.masonryComponent) {
                this._uiService.masonryComponent.reloadItems();
                this._uiService.masonryComponent.layout();
            }
        }));
        this.messageRecalled = this.actions$.pipe(ofType(MessageActions.MESSAGE_RECALLED), withLatestFrom(this._store
            .select(state => state.messageReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.mainChatReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.profileReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.uiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, messagesState, mainChatsState, allProfiles, uiState]) => {
            const action = val;
            let lastMessage = {};
            let targetChat = {};
            if (uiState.selectedMessage &&
                action.message.message_id === uiState.selectedMessage.message_id) {
                this._uiDispatchers.backFromGroupReplies();
            }
            if (!action.message.loggedInIsSender) {
                let unreadMsgsCount;
                if (!action.message.group_id) {
                    targetChat = mainChatsState.find(chat => chat.id === action.message.sender_id);
                }
                else {
                    targetChat = mainChatsState.find(chat => chat.id === action.message.group_id);
                }
                if (targetChat && !targetChat.selected) {
                    unreadMsgsCount = targetChat.unreadCounter
                        ? targetChat.unreadCounter
                        : 0;
                    if (!action.message.isRead) {
                        this._mainChatDispatchers.updateNumberOfUnreadMessages(targetChat.id, unreadMsgsCount > 0 ? --unreadMsgsCount : 0, !action.message.reply_to_message_id ? true : false, true);
                    }
                }
                else if (targetChat && targetChat.selected) {
                    if (!action.message.isViewed && !action.message.reply_to_message_id) {
                        this._mainChatDispatchers.decrementNumberOfNotViewedMessages(targetChat);
                    }
                }
            }
            else {
                if (!action.message.group_id) {
                    targetChat.id = action.message.receiver_id;
                    targetChat.type = INDIVIDUAL;
                }
                else {
                    targetChat.id = action.message.group_id;
                    targetChat.type = action.message.group_type === 100 ? GROUP : CHANNEL;
                }
            }
            if (targetChat) {
                lastMessage = this._mainChatDispatchers.getLastMessageInChat(messagesState, targetChat);
                if (lastMessage) {
                    const mainChat = Object.assign({}, targetChat);
                    if (targetChat.type === GROUP) {
                        mainChat.lastSenderIDInGroup = lastMessage.lastReplySenderID;
                        const senderProfile = allProfiles.find(prof => prof.user_id === lastMessage.lastReplySenderID);
                        mainChat.lastSenderNameInGroup = senderProfile
                            ? senderProfile.name
                            : null;
                    }
                    this._mainChatDispatchers.updateLastMessage(mainChat, lastMessage, true);
                }
                else {
                    this._mainChatDispatchers.updateLastMessage(targetChat, null);
                }
            }
        }));
        this.msgSent = this.actions$.pipe(ofType(MessageActions.MESSAGE_SENT), withLatestFrom(this._store
            .select(state => state.messageReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, messagesState]) => {
            const action = val;
            const message = messagesState.find(msg => msg.message_id === action.payload.message_id);
            if (message) {
                const mainChat = {};
                if (!message.group_id) {
                    mainChat.id = message.receiver_id;
                }
                else {
                    mainChat.id = message.group_id;
                }
                this._mainChatDispatchers.updateLastMessageStatus(mainChat.id, MessageSendingStatus.SENT, action.payload.reference);
            }
        }));
        this.msgDelivered = this.actions$.pipe(ofType(MessageActions.MESSAGE_DELIVERED), withLatestFrom(this._store
            .select(state => state.messageReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, messagesState]) => {
            const action = val;
            const message = messagesState.find(msg => msg.message_id === action.payload.message_id);
            if (message) {
                const mainChat = {};
                if (!message.group_id) {
                    mainChat.id = message.receiver_id;
                }
                else {
                    mainChat.id = message.group_id;
                }
                this._mainChatDispatchers.updateLastMessageStatus(mainChat.id, MessageSendingStatus.DELIVERED, action.payload.reference);
            }
        }));
        this.msgSeen = this.actions$.pipe(ofType(MessageActions.MESSAGE_SEEN), withLatestFrom(this._store
            .select(state => state.messageReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, messagesState]) => {
            const action = val;
            const message = messagesState.find(msg => msg.message_id === action.message_id);
            if (message) {
                const mainChat = {};
                if (!message.group_id) {
                    mainChat.id = message.receiver_id;
                }
                else {
                    mainChat.id = message.group_id;
                }
                this._mainChatDispatchers.updateLastMessageStatus(mainChat.id, MessageSendingStatus.SEEN, message.reference);
            }
        }));
        this.shareMessage = this.actions$.pipe(ofType(MessageActions.SHARE_MESSAGE), map((action) => {
            const msg = action.payload;
            const text = msg.media_id ? msg.caption : msg.text;
            this._socketGateway.sendSocketMessage(new PostMethods.SharePost(msg.sender_id, msg.sender_name, msg.message_id, msg.group_id, msg.group_name, msg.date, text, msg.media_id));
        }));
        this.likeMessage = this.actions$.pipe(ofType(MessageActions.LIKE_MESSAGE), map((action) => {
            const msg = action.payload;
            this._socketGateway.sendSocketMessage(new PostMethods.LikePost(1, msg.group_id, msg.message_id));
        }));
        this.unlikeMessage = this.actions$.pipe(ofType(MessageActions.UNLIKE_MESSAGE), map((action) => {
            const msg = action.payload;
            this._socketGateway.sendSocketMessage(new PostMethods.LikePost(0, msg.group_id, msg.message_id));
        }));
        this.historyMessage = this.actions$.pipe(ofType(MessageActions.HISTORY_EFFECT), withLatestFrom(this._store
            .select(state => state.messageReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, messagesState]) => {
            const action = val;
            const msg = action.message;
            const parentMessageId = msg.reply_to_message_id
                ? msg.reply_to_message_id
                : msg.message_id;
            const parentMessage = messagesState.find(localMSG => parentMessageId === localMSG.message_id);
            this._uiDispatchers.messageSelected(parentMessage);
            if (msg.reply_to_message_id) {
                const memberId = msg.from_admin ? msg.send_to_user_id : msg.sender_id;
                this._uiDispatchers.selectOneToOneReply(memberId);
            }
        }));
        // Send Logic
        this.sendMessageNowEffect = this.actions$.pipe(ofType(MessageActions.SEND_MESSAGE_NOW), map(val => {
            const action = val;
            this._messageGateway.sendMessage(action.payload);
        }));
        this.cancelScheduleMessageEffect = this.actions$.pipe(ofType(MessageActions.CANCEL_SCHEDULE_MESSAGE), map(val => {
            const action = val;
            this._socketGateway.sendSocketMessage(new MessagesMethods.CancelScheduledMessage(action.payload));
        }));
        this.sendTextEffect = this.actions$.pipe(ofType(MessageActions.SEND_TEXT), withLatestFrom(this._store
            .select(state => state.uiReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.myProfileReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.menuUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, uiState, myProfiles, menus]) => {
            const action = val;
            const loggedInProfile = myProfiles.find(profile => profile.relation === 'Other');
            if (action.misc && action.misc.inline_menu) {
                const responseMenu = this.getResponseMenu(menus.currentPage, action.misc.inline_menu[0].next_menu);
                if (responseMenu) {
                    action.misc.inline_menu.push(responseMenu);
                }
            }
            this.sendText(action.mainChat, action.parentMessage, action.userId, uiState, loggedInProfile, action.misc, action.text, null, null);
        }));
        this.sendInstantUrlEffect = this.actions$.pipe(ofType(MessageActions.SEND_INSTANT_URL), withLatestFrom(this._store
            .select(state => state.uiReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.myProfileReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.menuUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, uiState, myProfiles, menus]) => {
            const action = val;
            const loggedInProfile = myProfiles.find(profile => profile.relation === 'Other');
            if (action.misc && action.misc.inline_menu) {
                const responseMenu = this.getResponseMenu(menus.currentPage, action.misc.inline_menu[0].next_menu);
                if (responseMenu) {
                    action.misc.inline_menu.push(responseMenu);
                }
            }
            this.sendText(action.mainChat, action.parentMessage, action.userId, uiState, loggedInProfile, action.misc, null, action.instantArticle, null);
        }));
        this.sendMediaEffect = this.actions$.pipe(ofType(MessageActions.SEND_MEDIA), withLatestFrom(this._store
            .select(state => state.uiReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.myProfileReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.menuUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, uiState, myProfiles, menus]) => {
            const action = val;
            const loggedInProfile = myProfiles.find(profile => profile.relation === 'Other');
            if (action.misc && action.misc.inline_menu) {
                const responseMenu = this.getResponseMenu(menus.currentPage, action.misc.inline_menu[0].next_menu);
                if (responseMenu) {
                    action.misc.inline_menu.push(responseMenu);
                }
            }
            this.sendMedia(action.mainChat, action.parentMessage, action.userId, uiState, loggedInProfile, action.file, action.mediaType, action.previewMetadata, action.blobUrl, action.misc);
        }));
        this.sendCalendarEffect = this.actions$.pipe(ofType(MessageActions.SEND_CALENDAR), withLatestFrom(this._store
            .select(state => state.uiReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.myProfileReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.menuUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, uiState, myProfiles, menus]) => {
            const action = val;
            const loggedInProfile = myProfiles.find(profile => profile.relation === 'Other');
            if (action.misc && action.misc.inline_menu) {
                const responseMenu = this.getResponseMenu(menus.currentPage, action.misc.inline_menu[0].next_menu);
                if (responseMenu) {
                    action.misc.inline_menu.push(responseMenu);
                }
            }
            this.sendText(action.mainChat, action.parentMessage, action.userId, uiState, loggedInProfile, action.misc, null, null, action.calendar);
        }));
        /*************************************************************************************/
        this.getBlobUrlEffect = this.actions$.pipe(ofType(MessageActions.GET_BLOB_URL), map((action) => {
            this.getblobUrl(action.selectedFiles);
        }));
        this.retryOperation = this.actions$.pipe(ofType(MessageActions.RETRY_MEDIA_OPERATION), map((action) => {
            this.retryEvent(action.payload);
        }));
        this.cancelOperation = this.actions$.pipe(ofType(MessageActions.CANCEL_MEDIA_OPERATION), map((action) => {
            this.cancelEvent(action.payload);
        }));
        this.previewLinkMsg = this.actions$.pipe(ofType(MessageActions.PREVIEW_LINK_MESSAGE), map((action) => {
            this.previewLinkMessage(action.payload);
        }));
        this.recallMessage = this.actions$.pipe(ofType(MessageActions.RECALL_MESSAGE), map(val => {
            const action = val;
            this._socketGateway.sendSocketMessage(new MessagesMethods.RecallMessage(action.message.group_id, // set groupId from message
            action.message.message_id, action.message.reference, action.message.send_to_user_id));
        }));
        this.sendingCreateTag = this.actions$.pipe(ofType(MessageActions.SENDING_CREATE_TAG), withLatestFrom(this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual))), map(([val, selectedChat]) => {
            const action = val;
            this._socketGateway.sendSocketMessage(new TagMethods.SetTags(action.payload.id, action.payload.description, action.payload.name, action.payload.isPrivate, action.payload.recordIndex));
        }));
        this.sendingDeleteTag = this.actions$.pipe(ofType(MessageActions.SENDING_DELETE_TAG), withLatestFrom(this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual))), map(([val, selectedChat]) => {
            const action = val;
            this._socketGateway.sendSocketMessage(new TagMethods.DeleteTags([action.payload.id]));
        }));
        this.sendingSetMemberTags = this.actions$.pipe(ofType(MessageActions.SENDING_SET_MEMBER_TAGS), map((action) => {
            this._chatMemberDispatchers.chatMemberUpdated({
                user_id: action.payload.user_id,
                tags: action.payload.tags
            });
            this._socketGateway.sendSocketMessage(new TagMethods.SetMemberTags(action.payload.user_id, action.payload.tags ? action.payload.tags : []));
        }));
        this.sendingGetMessageMenuStats = this.actions$.pipe(ofType(MessageActions.GET_MESSAGE_MENU_STATS), map((action) => {
            this._socketGateway.sendSocketMessage(new MenuCallBackStatsMethods.GetMyCallBacksStats(action.payload));
        }));
        this.sendingGetInlineMessageCallBack = this.actions$.pipe(ofType(MessageActions.GET_INLINE_MESSAGE_CALLBACK), map((action) => {
            this._socketGateway.sendSocketMessage(new MessagesMethods.InlineCallBackMessage(action.payload.to_user_id, action.payload.button_callback, null, action.payload.chat_id, action.payload.message_id, action.payload.menu_ref, action.payload.button_label, action.payload.reference));
        }));
    }
    getAwardMessagesHistory() {
        // get award messages
        this._socketGateway.sendSocketMessage(new MessagesMethods.GetMessagesHistoryFromBusinessServer(null, null, null, null, null, null, null, null, null, 10 * SERVER_PAGE_SIZE, null, 0, 2));
    }
    getScheduleMessagesHistory() {
        // get schedule messages
        this._socketGateway.sendSocketMessage(new MessagesMethods.GetScheduledMessagesHistory());
    }
    getLastPostsHistory() {
        this._socketGateway.sendSocketMessage(new MessagesMethods.GetMessagesHistoryFromBusinessServer(null, null, null, null, null, null, null, null, null, SERVER_PAGE_SIZE, null, 0, 0));
    }
    getLastRepliesHistory() {
        // request to get replies message to order posts by lastReplay
        this._socketGateway.sendSocketMessage(new MessagesMethods.GetMessagesHistoryFromBusinessServer(null, null, null, null, null, null, null, null, null, SERVER_PAGE_SIZE, 1, 0, 0));
    }
    isMessageExisting(msg, messagesState) {
        const existingMessage = messagesState.find(message => (message.message_id && message.message_id === msg.message_id) ||
            (message.sender_id &&
                message.sender_id === msg.sender_id &&
                message.reference &&
                message.reference === msg.reference) ||
            (!message.sender_id &&
                message.reference &&
                message.reference === msg.reference));
        if (existingMessage) {
            return true;
        }
        else {
            return false;
        }
    }
    setMessageMisc(misc) {
        const msg = {};
        for (const key in misc) {
            if (misc.hasOwnProperty(key)) {
                msg[key] = misc[key];
            }
        }
        return msg;
    }
    sendText(mainChat, parentMessage, userId, uiState, loggedInProfile, misc, text, instantArticle, calendar) {
        let msg = MessageMiddleware.initMessageToBeSent(mainChat, parentMessage, userId, uiState, loggedInProfile);
        const msgMisc = this.setMessageMisc(misc);
        msg = Object.assign({}, msg, msgMisc);
        /**
         * This types are the same using same attr but diffrent message type
         */
        if (instantArticle) {
            msg.type = MessageTypes.ARTICLE;
            msg.url = instantArticle.linkPreviewUrl;
            // local Use
            msg.linkPreviewStatus = instantArticle.linkPreviewStatus;
            msg.linkPreviewUrl = instantArticle.linkPreviewUrl;
            msg.linkPreviewRootUrl = instantArticle.linkPreviewRootUrl;
            msg.linkPreviewTitle = instantArticle.linkPreviewTitle;
            msg.linkPreviewDescription = instantArticle.linkPreviewDescription;
            msg.linkPreviewImage = instantArticle.linkPreviewImage;
            msg.linkPreviewWidth = instantArticle.linkPreviewWidth;
            msg.linkPreviewHeight = instantArticle.linkPreviewHeight;
        }
        else if (calendar) {
            msg.type = MessageTypes.CALENDAR;
            msg.json = calendar;
        }
        else if (text) {
            msg.type = MessageTypes.TEXT;
            msg.text = text;
        }
        /**
         * For ui effect
         */
        if (msg.isChannelPost) {
            this._uiService.scrollToUpMethod();
        }
        else {
            this._uiService.scrollToBottomMethod();
        }
        this.sendMessage(msg);
    }
    sendMedia(mainChat, parentMessage, userId, uiState, loggedInProfile, file, mediaType, previewMetadata, blobUrl, misc) {
        let msg = MessageMiddleware.initMessageToBeSent(mainChat, parentMessage, userId, uiState, loggedInProfile);
        msg.mediaStatus = MessageMediaStatus.UPLOADING;
        msg.reference = this._helperService.getCurrentTime();
        msg.type = mediaType;
        if (misc) {
            const msgMisc = this.setMessageMisc(misc);
            msg = Object.assign({}, msg, msgMisc);
        }
        msg.title = file.name;
        msg.file_name = file.name;
        msg.media_id = file.name;
        msg.file_size = file.size;
        msg.tempFile = file;
        if (msg.isChannelPost) {
            this._uiService.scrollToUpMethod();
        }
        else {
            this._uiService.scrollToBottomMethod();
        }
        /* chek if  previewMetadata exist for media ( PHOTO, VIDEO & GIF ) */
        if (previewMetadata) {
            msg.localMedia = previewMetadata.mediaUrl;
            msg.document_name = previewMetadata.fileName;
            msg.media_height = previewMetadata.height ? previewMetadata.height : null;
            msg.media_width = previewMetadata.width ? previewMetadata.width : null;
            msg.caption = previewMetadata.caption ? previewMetadata.caption : null;
            msg.media_duration = previewMetadata.duration
                ? previewMetadata.duration
                : null;
            if (msg.schedule_date) {
                this._messageDispatchers.sendingScheduleMessage(msg);
            }
            else if (msg.award) {
                this._messageDispatchers.sendingAwardMessage(msg);
            }
            else if (msg.welcome) {
                this._chatDispatchers.sendingWelcomeMessage(msg);
                this._messageDispatchers.sendingWelcomeMessage(msg);
            }
            else {
                this._messageDispatchers.sendingMessage(msg);
            }
            this.uploadAndSendMsg(msg, this._previewArrayBuffer, file);
        }
        else if (!previewMetadata) {
            // this for voice note only
            if (!file.name) {
                this._uiDispatchers.showPopup(NO_FILE_NAME);
                return;
            }
            else if (!this._fileService.isMimeTypeValid(mediaType, file.type)) {
                this._uiDispatchers.showPopup(NOT_SUPPORTED_FILE_TYPE);
                return;
            }
            else if (!this._fileService.isFileSizeValid(mediaType, file.size)) {
                this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
                return;
            }
            else if (file.size <= 0) {
                this._uiDispatchers.showPopup(FILE_SIZE_ZERO_LENGTH);
                return;
            }
            /* Set local media info in store then upload and send media */
            this._fileService.getAudioMetadata(blobUrl).then(res => {
                msg.media_duration = res.duration;
                msg.localMedia = blobUrl;
                if (msg.schedule_date) {
                    this._messageDispatchers.sendingScheduleMessage(msg);
                }
                else if (msg.award) {
                    this._messageDispatchers.sendingAwardMessage(msg);
                }
                else if (msg.welcome) {
                    this._chatDispatchers.sendingWelcomeMessage(msg);
                    this._messageDispatchers.sendingWelcomeMessage(msg);
                }
                else {
                    this._messageDispatchers.sendingMessage(msg);
                }
                this._fileService
                    .readFileAsArrayBuffer(file)
                    .then(fileAsArrayBuffer => {
                    this.uploadAndSendMsg(msg, fileAsArrayBuffer, file);
                });
            });
        }
    }
    uploadAndSendMsg(msg, fileAsArrayBuffer, file) {
        let fileType = file.type;
        if (file.name.includes('.rar')) {
            fileType = 'application/x-rar-compressed';
        }
        let progressMonitor = 0;
        this.uploadSubscriptions[msg.reference] = this._uploadGateway
            .uploadWithProgress(fileAsArrayBuffer, fileType, file.name, msg.type === MessageTypes.DOCUMENT)
            .subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {
                const progressVal = Math.round((event.loaded / event.total) * 100);
                if (progressVal - progressMonitor >= 18 || progressVal === 100) {
                    progressMonitor = progressVal;
                    this._messageDispatchers.updateLoadingProgress(msg, progressVal);
                }
            }
            else if (event.type === HttpEventType.Response) {
                const res = event.body;
                msg.media_id = res.file;
                msg.mediaStatus = MessageMediaStatus.UPLOADED;
                msg.tempFile = null;
                msg.loadingProgress = 100;
                this.sendMessage(msg);
                delete this.uploadSubscriptions[msg.reference];
            }
        }, error => {
            msg.mediaStatus = MessageMediaStatus.UPLOAD_FAILED;
            this._messageDispatchers.setMessageMediaStatus(msg);
        });
    }
    retryToUploadAndSendMsg(msg) {
        msg.mediaStatus = MessageMediaStatus.UPLOADING;
        this._messageDispatchers.setMessageMediaStatus(msg);
        this._fileService
            .readFileAsArrayBuffer(msg.tempFile)
            .then(fileAsArrayBuffer => {
            this.uploadAndSendMsg(msg, fileAsArrayBuffer, msg.tempFile);
        });
    }
    sendMessage(msg) {
        // Check message type to add to the right store
        msg.reference = msg.reference
            ? msg.reference
            : this._helperService.getCurrentTime();
        if (msg.schedule_date) {
            this._messageDispatchers.sendingScheduleMessage(msg);
        }
        else if (msg.award) {
            this._messageDispatchers.sendingAwardMessage(msg);
        }
        else if (msg.welcome) {
            this._chatDispatchers.sendingWelcomeMessage(msg);
            this._messageDispatchers.sendingWelcomeMessage(msg);
        }
        else {
            this._messageDispatchers.sendingMessage(msg);
        }
        this._messageGateway.sendMessage(msg);
    }
    cancelUploadMediaFile(message) {
        if (this.uploadSubscriptions[message.reference]) {
            this.uploadSubscriptions[message.reference].unsubscribe();
            delete this.uploadSubscriptions[message.reference];
            message.mediaStatus = MessageMediaStatus.UPLOAD_FAILED;
            this._messageDispatchers.setMessageMediaStatus(message);
        }
    }
    cancelEvent(message) {
        if (message.mediaStatus === MessageMediaStatus.UPLOADING) {
            this.cancelUploadMediaFile(message);
        }
        else if (message.mediaStatus === MessageMediaStatus.DOWNLOADING) {
            this._messageDispatchers.cancelDownloadMediaMessage(message);
        }
    }
    retryEvent(message) {
        if (message.mediaStatus === MessageMediaStatus.UPLOAD_FAILED) {
            this.retryToUploadAndSendMsg(message);
        }
        else if (message.mediaStatus === MessageMediaStatus.DOWNLOAD_FAILED) {
            this._messageDispatchers.downloadMediaMessage(message);
        }
    }
    getblobUrl(event) {
        /* check on name, type and size before preview */
        this._uiDispatchers.hideDragDropScreen();
        if (!event.localFile.name) {
            this._uiDispatchers.showPopup(NO_FILE_NAME);
            return;
        }
        else if (!this._fileService.isMimeTypeValid(event.type, event.localFile.type)) {
            this._uiDispatchers.showPopup(NOT_SUPPORTED_FILE_TYPE);
            return;
        }
        else if (!this._fileService.isFileSizeValid(event.type, event.localFile.size)) {
            this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
            return;
        }
        else if (event.localFile.size <= 0) {
            this._uiDispatchers.showPopup(FILE_SIZE_ZERO_LENGTH);
            return;
        }
        this._uiDispatchers.showPreview();
        this._uiService._previewFiles.next(event.localFile);
        const previewMetadata = {};
        previewMetadata.fileName = event.localFile.name;
        previewMetadata.fileSize = event.localFile.size;
        this._fileService
            .readFileAsArrayBuffer(event.localFile)
            .then(fileAsArrayBuffer => {
            this._previewArrayBuffer = fileAsArrayBuffer;
            const mediaToGenrate = event.type === MessageTypes.GIF &&
                event.localFile.name.endsWith('.mp4')
                ? MessageTypes.VIDEO
                : event.type;
            this._fileService
                .readArrayBufferAsBlobUrl(fileAsArrayBuffer, mediaToGenrate)
                .then(blobUrl => {
                previewMetadata.mediaUrl = blobUrl;
                if (event.type === MessageTypes.PHOTO ||
                    (event.type === MessageTypes.GIF &&
                        event.localFile.name.endsWith('.gif'))) {
                    this._fileService
                        .getImageMetadata(blobUrl)
                        .then(res => {
                        previewMetadata.width = res.width;
                        previewMetadata.height = res.height;
                        this._uiService._previewMessageType.next(event.type);
                        this._uiService._previewMediaMetadata.next(previewMetadata);
                    })
                        .catch(err => {
                        this._uiService._previewMediaError.next(true);
                    });
                }
                else if (event.type === MessageTypes.VIDEO ||
                    (event.type === MessageTypes.GIF &&
                        event.localFile.name.endsWith('.mp4'))) {
                    this._fileService
                        .getVideoMetadata(blobUrl)
                        .then(res => {
                        previewMetadata.width = res.width;
                        previewMetadata.height = res.height;
                        previewMetadata.duration = res.duration;
                        if (!res.width) {
                            this._uiService._previewMessageType.next(MessageTypes.AUDIO);
                        }
                        else {
                            this._uiService._previewMessageType.next(event.type);
                        }
                        this._uiService._previewMediaMetadata.next(previewMetadata);
                    })
                        .catch(err => {
                        this._uiService._previewMediaError.next(true);
                    });
                }
                else if (event.type === MessageTypes.AUDIO) {
                    this._fileService
                        .getAudioMetadata(blobUrl)
                        .then(res => {
                        previewMetadata.duration = res.duration;
                        this._uiService._previewMessageType.next(event.type);
                        this._uiService._previewMediaMetadata.next(previewMetadata);
                    })
                        .catch(err => {
                        this._uiService._previewMediaError.next(true);
                    });
                }
                else if (event.type === MessageTypes.DOCUMENT) {
                    this._uiService._previewMessageType.next(event.type);
                    this._uiService._previewMediaMetadata.next(previewMetadata);
                }
            });
        });
    }
    previewLinkMessage(message) {
        if (message.type === MessageTypes.TEXT) {
            const url = this._linkPreviewGateway.getFirstLink(message.text);
            if (url) {
                this.setPreviewLinkDetails(message, url);
            }
        }
        else if (message.type === MessageTypes.ARTICLE) {
            if (message.url) {
                this.setPreviewLinkDetails(message, message.url);
            }
        }
    }
    setPreviewLinkDetails(message, url) {
        const msg = Object.assign({}, message);
        this._messageDispatchers.setLinkPreviewStatus(Object.assign({}, message, { linkPreviewStatus: MessageLinkPreviewStatus.LINK_PREVIEW_FETCHING }));
        this._linkPreviewGateway.getMetaFormUrl(url).subscribe(res => {
            if (res) {
                msg.linkPreviewUrl = res.linkPreviewUrl;
                msg.linkPreviewTitle = res.linkPreviewTitle;
                msg.linkPreviewDescription = res.linkPreviewDescription;
                msg.linkPreviewRootUrl = res.linkPreviewRootUrl;
                msg.linkPreviewStatus = MessageLinkPreviewStatus.LINK_PREVIEW_FETCHED;
                if (res.linkPreviewImage) {
                    msg.linkPreviewImage = this._linkPreviewGateway.replaceHttp(res.linkPreviewImage);
                    if (msg.type === MessageTypes.TEXT) {
                        this._fileService
                            .getImageMetadata(res.linkPreviewImage)
                            .then(imageMeta => {
                            msg.linkPreviewWidth = imageMeta.width;
                            msg.linkPreviewHeight = imageMeta.height;
                            this._messageDispatchers.linkPreviewDetailsReceived(msg);
                        })
                            .catch(err => {
                            this._messageDispatchers.linkPreviewDetailsReceived(msg);
                        });
                    }
                    else {
                        this._messageDispatchers.linkPreviewDetailsReceived(msg);
                    }
                }
                else {
                    this._messageDispatchers.linkPreviewDetailsReceived(msg);
                }
            }
            else {
                this._messageDispatchers.setLinkPreviewStatus(Object.assign({}, message, { linkPreviewStatus: MessageLinkPreviewStatus.LINK_PREVIEW_FAILED }));
            }
        }, err => {
            msg.linkPreviewStatus = MessageLinkPreviewStatus.LINK_PREVIEW_FAILED;
            this._messageDispatchers.linkPreviewDetailsReceived(msg);
        }, () => { });
    }
    getResponseMenu(menus, menu_ref) {
        return menus.find(m => m.menu_ref === menu_ref);
    }
    setTheCounterInStorage(massageID, massageSate) {
        this._storageService.setRecord(massageID, JSON.stringify(massageSate));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "getLastPostsHistoryEffect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "getLastRepliesHistoryEffect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "listScheduleMessages", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "listAwardMessages", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "listTags", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "initRcvdMsg", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "messagesSeen", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "hidePreview", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "downloadMessageThumbnail", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "cancelDownloadOfChatMsgs", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "cancelDownloadOfContactMsgs", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "downloadMessageMedia", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "cancelDownloadMessageMedia", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "updateExistingMessage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "messageReceived", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "sendingMsg", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "messageRecalled", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "msgSent", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "msgDelivered", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "msgSeen", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "shareMessage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "likeMessage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "unlikeMessage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "historyMessage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "sendMessageNowEffect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "cancelScheduleMessageEffect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "sendTextEffect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "sendInstantUrlEffect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "sendMediaEffect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "sendCalendarEffect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "getBlobUrlEffect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "retryOperation", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "cancelOperation", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "previewLinkMsg", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "recallMessage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "sendingCreateTag", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "sendingDeleteTag", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "sendingSetMemberTags", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "sendingGetMessageMenuStats", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MessageEffects.prototype, "sendingGetInlineMessageCallBack", void 0);
