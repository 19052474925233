<div class="main-header no-copyable-item">
  <div class="main-header-sidenav clickable-cursor logo-border">
    <div class="item header clickable-cursor">
      <a
        class="app-logo clickable-cursor"
        href="https://nandbox.com"
        target="_blank"
      >
        <img
          alt="nandbox"
          class="nandbox-logo"
          alt="nandbox Native App Builder logo"
          src="https://nandbox.com/wp-content/uploads/2022/10/nandbox-logo.webp"
          draggable="false"
          height="40"
          width="100%"
        />
        <img
          alt="nandbox"
          class="nandbox-logo-sml"
          src="./assets/img/logo-small.svg"
          height="40"
          width="100%"
        />
      </a>
    </div>
  </div>
  <!-- <div
    class="main-header-sidenav clickable-cursor logo-border-small"
    *ngIf="(layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus"
  >

    <div class="item header clickable-cursor">

      <img
        alt="nandbox logo"
        class="nandbox-logo"
        src="./assets/img/logo-small.svg"
        height="40"
      />
    </div>
  </div> -->
  <app-navbar-pagename
    style="margin-left: 20px;"
    [currentPage]="appManagementService?.currentPage$ | async"
    [appInfo]="appManagementService?.appInfo$ | async"
  ></app-navbar-pagename>
  <!-- <ng-container *ngIf="currentSection !== uiSections.HOME"> -->
  <app-countdown-timer
    *ngIf="billingsService?.getBillingsEndTriles$ | async as date"
    [endDate]="date"
  ></app-countdown-timer>
  <!-- </ng-container> -->

  <div class="full-w"></div>
  <ng-container
    *ngIf="(layoutService?.uiCollection$ | async)?.section as currentSection"
  >
    <div
      class="dashboard-action"
      *ngIf="
        (summaryService?.currentSummaryScreen$ | async) !==
          summaryScreens.CUSTOMIZE && currentSection === uiSections.HOME
      "
    >
      <button
        class="add-widget"
        (click)="summaryService.setCurrentScreen(summaryScreens.ADD_WIDGET)"
      >
        <div class="icon">
          <svg class="svg-icon icon-icon">
            <use
              xlink:href="/assets/svg/app-extra-icons.svg#icon-plus-add"
            ></use>
          </svg>
        </div>
        Add Widget
      </button>
      <button
        class="customize"
        (click)="summaryService.setCurrentScreen(summaryScreens.CUSTOMIZE)"
      >
        <div class="icon">
          <svg class="svg-icon icon-icon">
            <use
              xlink:href="/assets/svg/app-extra-icons.svg#icon-edit-pencil"
            ></use>
          </svg>
        </div>
        Customize
      </button>
    </div>
  </ng-container>
  <ng-container *appShowIfHasPrivilege="privilegesName?.listapp">
    <div
      class="billing"
      *ngIf="
        (summaryService?.currentSummaryScreen$ | async) !==
          summaryScreens.CUSTOMIZE && role == 1
      "
    >
      <button
        class="book-meeting"
        (click)="setTemplate()"
        *ngIf="
          (uiService.getEnableSetTemplateBtn$ | async) && !mainChat.subChannel
        "
      >
        set main chat
      </button>
      <button
        class="book-meeting"
        (click)="
          openLink('https://calendly.com/omar-elbahr/lets-build-your-app')
        "
      >
        Free Consultation
      </button>
      <button class="upgrade-plan" (click)="showSubscriptionModal()">
        Upgrade
      </button>
    </div>
  </ng-container>
  <div
    class="dashboard-action"
    *ngIf="
      (summaryService?.currentSummaryScreen$ | async) ===
        summaryScreens.CUSTOMIZE &&
      (layoutService?.uiCollection$ | async)?.section === uiSections.HOME
    "
  >
    <button class="done-btn" (click)="summaryService.updateDashboardList()">
      <div class="icon">
        <svg class="svg-icon icon-icon">
          <use
            xlink:href="/assets/svg/app-extra-icons.svg#icon-done-check"
          ></use>
        </svg>
      </div>
      Done
    </button>
  </div>

  <!-- <div class="search">
    <input
      placeholder="Search"
      class="form-control search-input"
      aria-label="search input"
      [formControl]="searchTerm"
    />
    <img class="search-icon" src="./assets/img/search.svg" alt="search" />
  </div> -->
  <div class="top-icons">
    <!-- Chats -->
    <div
      class="btn-group clickable-cursor list"
      #dd="bs-dropdown"
      dropdown
      appClickElsewhere
      (clickElsewhere)="dd.hide()"
    >
      <div class="menu" dropdownToggle>
        <div class="menu-select" style="width: 90%;">
          <app-profile-image
            class="head"
            [id]="(layoutService?.selectedChat$ | async)?.id"
            [chatType]="'Channel'"
            [size]="32"
          >
          </app-profile-image>
          <div class="truncate-text">
            {{ (layoutService?.selectedChat$ | async)?.name }}
          </div>
        </div>
        <div
          class="fas fa-chevron-down clickable-cursor"
          [class.fas-open]="dd?.isOpen"
        ></div>
      </div>
      <ul *dropdownMenu class="dropdown-menu no-copyable-item" role="menu">
        <div class="arrow"></div>
        <!-- <div class="main">
          <div class="title">
            Main App
          </div>
          <li
            class="menuitem clickable-cursor"
            role="menuitem"
            type="button"
            *ngFor="
              let channel of layoutService.allChannels$ | async;
              trackBy: TrackByFunction
            "
            (click)="selectChatEvent(channel)"
          >
            <app-profile-cell
              [chat]="channel"
              [mainName]="
                (appManagementService?.appInfo$ | async)?.channel_name
              "
              [size]="29"
              *ngIf="!channel?.subChannel"
            ></app-profile-cell>
          </li>
        </div>-->
        <div>
          <div class="selected-channel">
            <div class="channel">
              <app-profile-image
                class="head"
                [id]="(layoutService?.selectedChat$ | async)?.id"
                [chatType]="'Channel'"
                [size]="32"
              >
              </app-profile-image>
              <div style="width: 100%;">
                <div class="channel-title truncate-text" style="width: 70%;">
                  {{ (layoutService?.selectedChat$ | async)?.name }}
                </div>
                <div class="channel-desc">
                  {{
                    (layoutService?.selectedChat$ | async)?.subChannel
                      ? 'Channel'
                      : 'Main App'
                  }}
                </div>
              </div>
            </div>
            <svg class="svg-icon icon-done">
              <use xlink:href="/assets/svg/app-extra-icons.svg#icon-done"></use>
            </svg>
          </div>
          <div
            class="sub"
            *ngIf="(layoutService.allChannels$ | async)?.length > 1"
          >
            <ng-container
              *ngFor="
                let channel of layoutService.allChannels$ | async;
                trackBy: TrackByFunction
              "
            >
              <li
                class="menuitem clickable-cursor"
                style="position: relative;
                display: flex;
                align-items: center;"
                role="menuitem"
                type="button"
                *ngIf="
                  (layoutService?.selectedChat$ | async)?.id !== channel?.id
                "
                (click)="selectChatEvent(channel)"
              >
                <app-profile-cell
                  [chat]="channel"
                  [size]="29"
                  style="width: 100%;"
                ></app-profile-cell>
                <span
                  *ngIf="!channel?.subChannel"
                  style="color: #01a1ee; font-size: 1.2em; font-weight: 400; position: absolute;
                  right: 14px;"
                >
                  Main App
                </span>
              </li>
            </ng-container>
            <!-- <ng-container
              *ngIf="(layoutService.allChannels$ | async)?.length <= 1"
            >
              <div class="no-channels">
                There is no  Channel
              </div>
            </ng-container> -->
          </div>
        </div>
      </ul>
    </div>
    <!-- End: Chats -->
    <div class="top-icons-actions" style="border-left: solid 1px #dfe5ea;">
      <!-- <div
        class="notification clickable-cursor"
        (click)="selectTalkToAdmin()"
        mat-raised-button
        matTooltip="Notifications"
        matTooltipPosition="above"
        matTooltipClass="multiline-tooltip"
      >
        <svg class="clickable-cursor svg-icon top-icon">
          <use xlink:href="./assets/svg/app-sections.svg#icon-bell"></use>
        </svg>
        <span class="messages"></span>
      </div> -->
      <div
        class="help-icon"
        (click)="openHelpWindow()"
        style="margin-left: 6px;"
      >
        <svg
          class="clickable-cursor svg-icon top-icon"
          style="margin-right: 4px;"
        >
          <use xlink:href="./assets/svg/app-sections.svg#icon-help-black"></use>
        </svg>
        Help
      </div>
      <div dropdown placement="bottom left" style="position: relative;">
        <div class="help-icon" style="border-radius: 50%;" dropdownToggle>
          <svg class="clickable-cursor svg-icon top-icon">
            <use
              xlink:href="./assets/svg/app-sections.svg#icon-settings-black"
            ></use>
          </svg>
        </div>
        <ul
          *dropdownMenu
          class="dropdown-menu no-copyable-item"
          role="menu"
          style="width: 180px;"
        >
          <div class="arrow"></div>
          <ng-container *ngIf="authService?.authCollection$ | async as member">
            <li
              class="item clickable-cursor"
              role="menuitem"
              type="button"
              (click)="goToBillingScreen()"
              *ngIf="member?.role && member?.role === 1"
            >
              <svg
                class="svg-icon icon-icon"
                style="width: 1.5em; height: 1.5em;"
              >
                <use
                  xlink:href="/assets/svg/app-extra-icons.svg#icon-monetization"
                ></use>
              </svg>
              Billing
            </li>

            <li
              class="item clickable-cursor"
              role="menuitem"
              type="button"
              (click)="goToMyChannelScreen()"
              *ngIf="!member?.role || member?.role !== 1"
            >
              <svg class="svg-icon icon-icon">
                <use
                  xlink:href="./assets/svg/app-sections.svg#icon-channel"
                ></use>
              </svg>
              My Channel
            </li>
          </ng-container>
        </ul>
      </div>

      <div dropdown placement="bottom left" style="position: relative;">
        <div class="help-icon" style="border-radius: 50%;" dropdownToggle>
          <svg class="clickable-cursor svg-icon top-icon">
            <use xlink:href="./assets/svg/app-sections.svg#icon-person"></use>
          </svg>
        </div>
        <ul *dropdownMenu class="dropdown-menu no-copyable-item" role="menu">
          <div class="arrow"></div>
          <div class="profile">
            <div class="profile-icon">
              <svg class="svg-icon icon-icon">
                <use
                  xlink:href="/assets/svg/app-sections.svg#icon-person"
                ></use>
              </svg>
            </div>
            <div>
              <div class="profile-title">
                {{ (authService?.authCollection$ | async)?.name }}
              </div>
              <div
                class="profile-desc"
                *ngIf="authService?.authCollection$ | async as member"
              >
                {{ getRoleName(member?.role) }}
              </div>
            </div>
          </div>
          <!-- <li
            class="item clickable-cursor"
            role="menuitem"
            type="button"
            [class.disapled]="true"
          >
            <svg class="svg-icon icon-icon">
              <use xlink:href="/assets/svg/app-sections.svg#icon-person"></use>
            </svg>
            Profile
          </li> -->
          <li
            class="item clickable-cursor"
            role="menuitem"
            type="button"
            (click)="logoutEvent()"
          >
            <svg class="svg-icon icon-icon">
              <use
                xlink:href="/assets/svg/app-extra-icons.svg#icon-logout"
              ></use>
            </svg>
            Log Out
          </li>
        </ul>
      </div>

      <!-- <ng-container *appShowIfHasPrivilege="privilegesName.editsettings">
        <ng-container *appHideIfSubChat="privilegesName.editsettings">
          <div
            class="help-icon"
            mat-raised-button
            matTooltip="Change Password"
            matTooltipPosition="above"
            matTooltipClass="multiline-tooltip"
            (click)="sectionEvent(uiSections.SETTINGS)"
          >
            <svg class="clickable-cursor svg-icon top-icon">
              <use
                xlink:href="./assets/svg/app-sections.svg#icon-change-password"
              ></use>
            </svg>
          </div>
        </ng-container>
      </ng-container> -->
    </div>
    <!-- <app-lang-selector></app-lang-selector> -->
  </div>
</div>
<!-- <div
  class="main-header-post no-copyable-item"
  *ngIf="(layoutService?.uiCollection$ | async)?.section === uiSections.POSTS"
>
  <app-groups-container style="width: 100%;"></app-groups-container>
</div> -->
