import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  EventEmitter,
  OnInit,
  HostListener
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import {
  MessageMediaStatus,
  MessageTypes,
  LEFT,
  RIGHT,
  MEDIA_PROGRESS_THEME_COLOR,
  CHAT_THEME_COLOR,
  LinkPreviewOptions,
  MessageLinkPreviewStatus
} from 'src/models/constants';
import { BotMenu } from 'src/models/IBotMenu';
import { IMessage } from 'src/models/IMessage';
import { UiService } from '../../core/ui.service';
import { DarkThemeBubble } from 'src/models/chatThemes';

@Component({
  selector: 'app-bubbel-cell',
  templateUrl: './bubbel-cell.component.html',
  styleUrls: ['./bubbel-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BubbelCellComponent implements OnInit {
  @Input() message: IMessage;
  @Input() isSubChat: boolean;
  @Input() chatType: string;
  @Input() chatName: string;
  @Input() isOneToOneReplie: boolean;
  @Input() isAdmin: boolean;
  @Input() hideActions = false;

  @Output() retryNotify = new EventEmitter<IMessage>();
  @Output() cancelNotify = new EventEmitter<IMessage>();
  winWidth: number;
  height: number;
  width: number;
  menus: BotMenu;
  mType: typeof MessageTypes = MessageTypes;
  linkPreviewStyle: typeof LinkPreviewOptions = LinkPreviewOptions;
  msgLinkPreviewStat: typeof MessageLinkPreviewStatus = MessageLinkPreviewStatus;

  @Input() mainStyle: any;

  constructor(public uiService: UiService, private _sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.winWidth = window.innerWidth;
    console.log(this.isOneToOneReplie, this.isSubChat);
    if (this.winWidth < 1100) {
      this.height = 200;
      this.width = 200;
    } else if (this.winWidth <= 1400) {
      this.height = 200;
      this.width = 200;
    } else {
      this.height = 200;
      this.width = 200;
    }

    if (this.message.inline_menu) {
      this.menus = this.message.inline_menu[0];
    }
  }

  ngOnChanges() {
    if (this.message.inline_menu && this.message.sender_type === 'Bot') {
      this.menus = this.message.inline_menu[0];
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.winWidth = window.innerWidth;
    if (this.winWidth < 1100) {
      this.height = 200;
      this.width = 200;
    } else if (this.winWidth <= 1400) {
      this.height = 200;
      this.width = 200;
    } else {
      this.height = 200;
      this.width = 200;
    }
  }

  themeColorMedia = MEDIA_PROGRESS_THEME_COLOR;
  themeColorFile = CHAT_THEME_COLOR;
  get messageStyle() {
    return {
      'left-bubbel': this.message.position === LEFT && !this.isOneToOneReplie,
      'right-bubbel': this.message.position === RIGHT && !this.isOneToOneReplie,
      'channel-reply': this.isOneToOneReplie,
      text:
        this.message.type === MessageTypes.TEXT ||
        this.message.type === MessageTypes.TEXT_FILE,
      media:
        this.message.type === MessageTypes.LOCATION ||
        this.message.type === MessageTypes.PHOTO ||
        this.message.type === MessageTypes.GIF ||
        this.message.type === MessageTypes.VIDEO,
      contact: this.message.type === MessageTypes.CONTACT,
      audio:
        this.message.type === MessageTypes.VOICE ||
        this.message.type === MessageTypes.AUDIO,
      file: this.message.type === MessageTypes.DOCUMENT
    };
  }

  getReplies(message: IMessage) {
    if (this.isSubChat) {
      this.uiService.selectSubGroupMessage(message);
    } else {
      this.uiService.selectedMessage(message);
    }
    if (!message.rcvdHistoryReply) {
      if (this.isSubChat) {
        this.uiService.requestHistory(
          message.message_id,
          null,
          null,
          message.group_id
        );
      } else {
        this.uiService.requestHistory(message.message_id);
      }
    }
  }

  retryEvent($event) {
    if ($event) {
      this.retryNotify.emit(this.message);
    }
  }

  cancelEvent($event) {
    if ($event) {
      this.cancelNotify.emit(this.message);
    }
  }

  fullScreenEvent(mediaStatus: string) {
    if (
      mediaStatus === MessageMediaStatus.DOWNLOADED ||
      mediaStatus === MessageMediaStatus.UPLOADED
    ) {
      this.uiService.showMediaScreen(this.message);
    }
  }

  recallEvent() {
    this.uiService.recallMessage(this.message);
  }

  get media() {
    if (this.message.localMedia) {
      return this._sanitizer.bypassSecurityTrustUrl(this.message.localMedia);
    }
    return '';
  }

  buttonClicked(event) {
    const buttonData = event;
    const currentMenu = this.menus;
    buttonData.to_user_id = this.message.sender_id;
    // buttonData.chat_id = this.chat.id;
    buttonData.reference = this.message.reference.toString();
    buttonData.menu_ref = this.message.menu_ref;
    if (this.message.sender_type !== 'Bot') {
      for (var i = 0; i < this.message.inline_menu.length; i++) {
        if (
          this.message.inline_menu[i] &&
          this.message.inline_menu[i].menu_id === currentMenu.next_menu
        ) {
          this.menus = this.message.inline_menu[i];
        }
      }
    } else {
      this.uiService.getInlineMessageCallBack(buttonData);
    }
  }

  get cellColor() {
    if (this.mainStyle) {
      return {
        'background-color':
          this.mainStyle && this.message.position === RIGHT
            ? this.mainStyle.rightBubbelBgColor
            : this.mainStyle.leftBubbelBgColor,
        color: this.mainStyle.primiryTextColor
      };
    }
  }

  setTemplate() {
    const massage: any = {
      echo: 1,
      chat_id: this.message.group_id,
      reference: this.message.reference,
      tags: this.message.tags || []
    };
    if (this.message.tab) {
      massage.tab = this.message.tab;
    }
    if (this.message.sticky_title) {
      massage.sticky_title = this.message.sticky_title;
    }
    if (this.message.sticky_desc) {
      massage.sticky_desc = this.message.sticky_desc;
    }
    if (this.message.menu_ref && this.message.inline_menu) {
      massage.menu_ref = this.message.menu_ref;
      massage.inline_menu = this.message.inline_menu;
      massage.reply_to_admin = true;
    }
    if (this.message.c_code && this.message.c_exp) {
      massage.c_code = this.message.c_code;
      massage.c_exp = this.message.c_exp;
    }
    if ('style' in this.message) {
      massage.style = this.message.style;
    }
    if ('bg_color' in this.message) {
      massage.bg_color = this.message.bg_color;
    }
    if (this.message.type === this.mType.VIDEO) {
      massage.video = this.message.media_id;
      massage.width = this.message.media_width;
      massage.height = this.message.media_height;
      massage.size = this.message.file_size;
      massage.caption = this.message.caption;
      massage.duration = this.message.media_duration;
    } else if (
      this.message.type === this.mType.PHOTO ||
      this.message.type === this.mType.GIF
    ) {
      massage.caption = this.message.caption;
      massage.photo = this.message.media_id;
      massage.width = this.message.media_width;
      massage.height = this.message.media_height;
      massage.size = this.message.file_size;
    } else if (this.message.type === this.mType.AUDIO) {
      massage.title = this.message.title;
      massage.audio = this.message.media_id;
      massage.size = this.message.file_size;
      massage.caption = this.message.caption;
      massage.duration = this.message.media_duration;
    } else if (this.message.type === this.mType.DOCUMENT) {
      massage.document = this.message.media_id;
      massage.size = this.message.file_size;
    } else if (this.message.type === this.mType.ARTICLE) {
      massage.title = this.message.linkPreviewTitle;
      massage.description = this.message.linkPreviewDescription;
      massage.photo_url = this.message.linkPreviewImage;
      massage.url = this.message.linkPreviewUrl;
    } else if (this.message.type === this.mType.TEXT) {
      massage.text = this.message.text;
    } else if (this.message.type === this.mType.CALENDAR) {
      massage.json = this.message.json;
      massage.photo = this.message.json.photo_id;
      massage.title = this.message.json.title;
      massage.description = this.message.json.description;
      massage.calendar_id = this.message.json.id;
    }

    const messageType = this.message.type.toLowerCase();
    this.uiService.setTemplateMessage(
      this.message.group_id,
      messageType,
      this.message.message_id,
      massage
    );
  }
}
