import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { ChatDispatchers } from '../chats/chat.dispatchers';
import { UiService } from '../../core/ui.service';
import { MessageDispatchers } from '../messages/message.dispatchers';
import * as MainChatMethods from 'src/models/MainChat';
import * as MessagesMethods from 'src/models/IMessage';
import { SERVER_PAGE_SIZE, GROUP, CHANNEL, PROJECT_NAME, INDIVIDUAL_PROJECT, MERCHANT_PROJECT, REACHED_END } from 'src/models/constants';
import * as isEqual from 'lodash.isequal';
import { StorageService } from 'src/app/core/storage.service';
export class MainChatEffects {
    constructor(actions$, _messageDispatchers, _chatDispatchers, uiService, _storageService, _socketGateway, _store) {
        this.actions$ = actions$;
        this._messageDispatchers = _messageDispatchers;
        this._chatDispatchers = _chatDispatchers;
        this.uiService = uiService;
        this._storageService = _storageService;
        this._socketGateway = _socketGateway;
        this._store = _store;
        this.mainChatSelected = this.actions$.pipe(ofType("MAIN_CHAT_SELECTED" /* MAIN_CHAT_SELECTED */), map((action) => {
            this._messageDispatchers.readChatMessages(action.mainChat);
            if (action.mainChat.type === GROUP || action.mainChat.type === CHANNEL) {
                this._chatDispatchers.chatSelected(action.mainChat.id);
            }
        }));
        this.mainChatDeselected = this.actions$.pipe(ofType("MAIN_CHAT_DESELECTED" /* MAIN_CHAT_DESELECTED */), withLatestFrom(this._store
            .select(state => state.mainChatReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mainChats]) => {
            const action = val;
            const targetChat = mainChats.find(chat => chat.id === action.id);
            if (targetChat) {
                this._messageDispatchers.chatDeselected(targetChat);
            }
        }));
        this.replyReceivedInSelectedChat = this.actions$.pipe(ofType("REPLY_RECEIVED_IN_SELECTED_CHAT" /* REPLY_RECEIVED_IN_SELECTED_CHAT */), map((action) => {
            this.uiService.replyRcvdInSelectedChatNotify(action.message);
        }));
        this.chatHistory = this.actions$.pipe(ofType("REQUEST_HISTORY" /* REQUEST_HISTORY */), withLatestFrom(this._store
            .select(state => state.mainChatReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.uiReducer.selectedSubChat)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.repliesEOPReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mainChats, selectedChat, selectedSubChat, repliesEOPList]) => {
            const action = val;
            if (selectedChat || selectedSubChat) {
                const targetChat = mainChats.find(chat => {
                    if (action.subChatID) {
                        return chat.id === action.subChatID;
                    }
                    else {
                        return chat.id === selectedSubChat.id;
                    }
                });
                if (targetChat) {
                    if (!action.parentMsgID) {
                        this.getChatHistory(targetChat, targetChat.endOfPage);
                    }
                    else {
                        let endOfPage = null;
                        let level = null;
                        let proceedWithRequest = true;
                        const replyRecord = repliesEOPList.find(item => item.messageID === action.parentMsgID);
                        if (replyRecord) {
                            if (action.userID) {
                                let userRecord = null;
                                if (replyRecord.usersRepliesEOPList) {
                                    userRecord = replyRecord.usersRepliesEOPList.find(user => user.userID === action.userID);
                                }
                                if (userRecord) {
                                    if (action.requestOnce) {
                                        proceedWithRequest = false; // do not get history as the flag requestOnce is true and you got history before
                                    }
                                    endOfPage = userRecord.eopSecondDepth;
                                }
                            }
                            else {
                                endOfPage = replyRecord.eopFirstDepth;
                            }
                        }
                        if (targetChat.isAdmin &&
                            targetChat.type === CHANNEL &&
                            !action.userID) {
                            level = 2;
                        }
                        else {
                            level = 3;
                        }
                        if (proceedWithRequest) {
                            this.getChatHistory(targetChat, endOfPage, action.parentMsgID, level, action.userID);
                        }
                    }
                }
            }
        }));
        this.contactsNamesReceived = this.actions$.pipe(ofType("CONTACTS_NAMES_RECEIVED" /* CONTACTS_NAMES_RECEIVED */), withLatestFrom(this._store
            .select(state => state.profileReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.mainChatReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, profilesState, mainChats]) => {
            const action = val;
            //set profile details to Cache
            profilesState.map(payload => {
                const mainChat = mainChats.find(res => res.id === payload.user_id);
                const chat = Object.assign({}, payload, { name: mainChat.name });
                this._storageService.setProfileToCache(chat.user_id, chat);
            });
        }));
    }
    getChatHistory(chat, endOfPage, parentMsgID, level, userID) {
        if (endOfPage !== REACHED_END && chat && chat.id) {
            if (PROJECT_NAME === INDIVIDUAL_PROJECT) {
                this._socketGateway.sendSocketMessage(new MainChatMethods.GetChatHistoryFromMobile(chat.id, endOfPage, SERVER_PAGE_SIZE, parentMsgID, level, userID));
            }
            else if (PROJECT_NAME === MERCHANT_PROJECT) {
                this.getChatHistoryFromBusinessServer(chat.id, endOfPage ? endOfPage : 0, SERVER_PAGE_SIZE, parentMsgID, level, userID);
            }
        }
    }
    getChatHistoryFromBusinessServer(chat_id, reference, page_size, reply_to_message_id, lv, user_id) {
        this._socketGateway.sendSocketMessage(new MessagesMethods.GetMessagesHistoryFromBusinessServer(null, reply_to_message_id, null, null, null, null, null, null, user_id, page_size, lv, reference, 0, chat_id));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MainChatEffects.prototype, "mainChatSelected", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MainChatEffects.prototype, "mainChatDeselected", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MainChatEffects.prototype, "replyReceivedInSelectedChat", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MainChatEffects.prototype, "chatHistory", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MainChatEffects.prototype, "contactsNamesReceived", void 0);
