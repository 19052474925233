import { Injectable } from '@angular/core';
import { UiService } from 'src/app/core/ui.service';
import { SummaryScreens, UISections, UserStatus } from 'src/models/constants';
import { BlacklistService } from '../blacklist/blacklist.service';
import { BookingsService } from '../bookings/bookings.service';
import { BundlesService } from '../bundles/bundles.service';
import { CalendarsService } from '../calendars/calendars.service';
import { EventsService } from '../events/events.service';
import { MembersService } from '../members/members.service';
import { MobileStoresService } from '../mobile-store/mobile-store.service';
import { PackagesService } from '../packages/packages.service';
import { ProductsService } from '../products/products.service';
import { StoreMenuService } from '../store-menu/store-menu.service';
import { SubChatsService } from '../sub-chats/sub-chats.service';

import * as MessageMethods from 'src/models/IMessage';
import { WhitelistService } from '../white-list/white-list.service';
import { DashboardUI, WidgetUI } from 'src/models/summaryUI';
import { SummaryUIDispatchers } from 'src/app/store/summaryUI/summaryUI.dispatchers';
import { BehaviorSubject } from 'rxjs';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { DashboardService } from '../dashboard.service';
import { ChartDispatchers } from 'src/app/store/chartStore/chart.dispatchers';

@Injectable({ providedIn: 'root' })
export class SummaryService {
  userStatus = UserStatus;
  sections = UISections;
  featuresIds: string[] = [];

  private _currentSummaryScreen = new BehaviorSubject<string>(
    SummaryScreens.SUMMARY_VIEW
  );
  public currentSummaryScreen$ = this._currentSummaryScreen.asObservable();

  constructor(
    private memberService: MembersService,
    private bookingService: BookingsService,
    private eventService: EventsService,
    private calendarService: CalendarsService,
    private storesService: MobileStoresService,
    private productService: ProductsService,
    private bundleService: BundlesService,
    private packagesService: PackagesService,
    private storeMenuService: StoreMenuService,
    private blacklistService: BlacklistService,
    private uiService: UiService,
    private subChatsService: SubChatsService,
    private whitelistService: WhitelistService,
    private summaryUIDispatchers: SummaryUIDispatchers,
    private _storeSelector: RootStoreSelectors,
    private _dashboardService: DashboardService,
    private chartUIDispatchers: ChartDispatchers
  ) {}

  setCurrentScreen(screen: string) {
    this._currentSummaryScreen.next(screen);
  }

  resetPaging(card) {
    switch (card.section) {
      case this.sections.POSTS:
        this.uiService.setPostsPageNumber(1);
        break;
      case this.sections.USERS:
        this.memberService.resetPaging(null, this.userStatus.ACTIVE);
        break;
      case this.sections.SUBCHANNEL:
        this.subChatsService.resetPaging(card.section);
        break;
      case this.sections.SUBGROUP:
        this.subChatsService.resetPaging(card.section);
        break;
      case this.sections.BOOKING:
        this.bookingService.resetPaging();
        break;
      case this.sections.EVENTS:
        this.eventService.resetPaging();
        break;
      case this.sections.CALENDAR:
        this.calendarService.resetPaging();
        break;
      case this.sections.MSTORE:
        this.storesService.resetPaging();
        break;
      case this.sections.PRODUCTS:
        this.productService.resetPaging();
        break;
      case this.sections.BUNDLES:
        this.bundleService.resetPaging();
        break;
      case this.sections.PACKAGES:
        this.packagesService.resetPaging();
        break;
      case this.sections.STOREMENU:
        this.storeMenuService.resetPaging();
        break;
      case this.sections.BLACKLIST:
        this.blacklistService.resetPaging(null);
        break;
      case this.sections.WHITELIST:
        this.whitelistService.resetWhitelistPaging(null);
        break;
    }
  }

  editItemEvent(item, section) {
    this._dashboardService.setSection(section);
    switch (section) {
      case this.sections.POSTS:
        break;
      case this.sections.USERS:
        this.memberService.selectMemberEvent(item);
        break;
      case this.sections.SUBCHANNEL:
        this.subChatsService.showEditSubGroupScreen(item);
        break;
      case this.sections.SUBGROUP:
        this.subChatsService.showEditSubGroupScreen(item);
        break;
      case this.sections.BOOKING:
        setTimeout(() => this.bookingService.showEditBookingScreen(item), 100);
        break;
      case this.sections.EVENTS:
        this.eventService.showEditEventScreen(item);
        break;
      case this.sections.CALENDAR:
        this.calendarService.showEditCalendarScreen(item);
        break;
      case this.sections.MSTORE:
        this.storesService.updateItemEvent(item);
        break;
      case this.sections.PRODUCTS:
        this.productService.updateItemEvent(item);
        break;
      case this.sections.BUNDLES:
        this.bundleService.updateItemEvent(item);
        break;
      case this.sections.PACKAGES:
        this.packagesService.updateItemEvent(item);
        break;
      case this.sections.STOREMENU:
        setTimeout(() => this.storeMenuService.updateItemEvent(item), 100);
        break;
      case this.sections.BLACKLIST:
        break;
      case this.sections.WHITELIST:
        break;
    }
  }

  previewLinkMessage(massage: MessageMethods.IMessage) {
    this.uiService.previewLinkMessage(massage);
  }

  get dashboardList$() {
    return this._storeSelector.dashboardList$;
  }

  createNewWidget(widget: WidgetUI) {
    this.resetPaging(widget);
    this.summaryUIDispatchers.createNewWidget(widget);
  }

  setDashboard(dashboard: DashboardUI) {
    this.summaryUIDispatchers.setDashboard(dashboard);
  }

  reOrderList(widgets: WidgetUI[]) {
    this.summaryUIDispatchers.reOrderList(widgets);
  }

  updateDashboardList() {
    this.summaryUIDispatchers.updateDashboardList();
    this.setCurrentScreen(SummaryScreens.SUMMARY_VIEW);
  }

  updateWidget(widget: WidgetUI) {
    this.summaryUIDispatchers.updateWidget(widget);
  }

  removeWidget(widget: WidgetUI) {
    this.summaryUIDispatchers.removeWidget(widget);
  }

  getChartData(
    chat_id: string,
    chart_type: number,
    interval: number,
    startDate: string,
    endDate: string
  ) {
    this.chartUIDispatchers.getChartData(
      chat_id,
      chart_type,
      interval,
      startDate,
      endDate
    );
  }

  getChartData$(type) {
    return this._storeSelector.getChartData$(type);
  }
  getChartDataStatus$(type) {
    return this._storeSelector.getChartDataStatus$(type);
  }
}
