import {
  Directive,
  HostListener,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UiService } from '../core/ui.service';

import { RootStoreSelectors } from '../store/root-store.selectors';

@Directive({
  selector: '[appCancelActionIfNotHasPrivilege]'
})
export class AppCancelActionIfNotHasPrivilegeDirective
  implements OnInit, OnDestroy {
  @Input() privilege: string;
  @Input() moduleID: string;
  @Output() cancelNotifier = new EventEmitter<boolean>();
  stop$ = new Subject();
  constructor(
    private _rootStore: RootStoreSelectors,
    private uiService: UiService
  ) {}

  ngOnInit() {}

  private stopAction(event: any) {
    event = event || window.event;
    if (event) {
      event = event.originalEvent || event;

      if (event.stopPropagation) {
        event.stopPropagation();
      }
      if (event.preventDefault) {
        event.preventDefault();
      }
      event.returnValue = false;
      event.cancelBubble = true;
    }
    return false;
  }

  @HostListener('ngSubmit', ['$event'])
  @HostListener('click', ['$event'])
  @HostListener('change', ['$event'])
  @HostListener('drop', ['$event'])
  onClick($event: Event) {
    if (this.privilege) {
      this._rootStore.modules$
        .pipe(takeUntil(this.stop$))
        .subscribe(modules => {
          const isExict = modules.includes(this.privilege);
          if (!isExict) {
            this.uiService.togglePopupUpgradePlanModule(this.privilege);
            this.stopAction($event);
            this.cancelNotifier.emit(true);
          }
        })
        .unsubscribe();
    }
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  check(privilege) {
    let status = false;
    if (privilege) {
      this._rootStore.modules$
        .pipe(takeUntil(this.stop$))
        .subscribe(modules => {
          const isExict = modules.includes(privilege);
          if (!isExict) {
            this.uiService.togglePopupUpgradePlanModule(privilege);
            status = false;
          } else {
            status = true;
          }
        })
        .unsubscribe();
    }
    return status;
  }
}
