import { Collection } from './collectionsUI';
import {
  DELETE_PRODUCT_ELEMENT_METHOD,
  DELETE_PRODUCT_METHOD,
  GET_PRODUCT_ITEM_METHOD,
  LIST_PRODUCT_ITEM_METHOD,
  ListRequestDetails,
  MERCHANT_PAGE_SIZE,
  PageDirection,
  SCREEN_STATUS,
  SET_PRODUCT_ITEM_ARRAY,
  SET_PRODUCT_ITEM_CALLBACK,
  SET_PRODUCT_TO_COLLECTIONS_METHOD
} from './constants';

export interface ProductStoreUI {
  currentPage?: ProductStore[]; // will get it from the server result
  eop?: number; // will get it from the server result // end of page
  sop?: number; // will get it from the server result // start of page
  hash?: string;
  status?: SCREEN_STATUS; // So that if we want to add a progress circle or something until we receive the request
  direction?: PageDirection;
  previousRequest?: ListRequestDetails;
  productGroupId?: number;
  ownerGroupId?: string;
  selecteedProductStore?: ProductStore;
}

export interface ProductStore {
  id?: any;
  name?: string;
  description?: string;
  service_profile_id?: number;
  type?: number;
  image?: ProductImage[];
  price?: number;
  compare_at_price?: number;
  sku?: string;
  bundle?: any;
  status?: string;
  option?: ProductOption[];
  attribute?: UIAttribute;
  tag?: string[];
  parent_id?: number;
  variant?: any[];
  assign_collection?: Collection[];
}

export interface UIAttribute {
  cost?: number;
  tax?: number;
  quantity?: number;
  barcode?: string;
  option1?: string;
  option2?: string;
  option3?: string;
  description?: string;
  hasSkuOrBarcode?: number;
}

export interface ProductImage {
  width?: number;
  height?: number;
  url?: string;
}

export interface ProductOption {
  id?: string;
  name?: string;
  values?: string[];
}

export class GetProductStoreMethod {
  readonly page_size = 100;
  readonly method = LIST_PRODUCT_ITEM_METHOD;
  constructor(public direction?: number, public reference?: number) {}
}
export class SetProductStoreMethod {
  readonly method = SET_PRODUCT_ITEM_CALLBACK;
  constructor(
    public id?: any,
    public name?: string,
    public description?: string,
    public service_profile_id?: number,
    public type?: number,
    public price?: number,
    public image?: ProductImage[],
    public sku?: string,
    public status?: string,
    public compare_at_price?: number,
    public option?: ProductOption[],
    public attribute?: UIAttribute,
    public tag?: any[],
    public ref?: string
  ) {}
}

export class SetProductItemArray {
  readonly method = SET_PRODUCT_ITEM_ARRAY;
  constructor(
    public products?: ProductStore[],
    public parent_id?: number,
    public type?: number
  ) {}
}

export class GetProductItemMethod {
  readonly method = GET_PRODUCT_ITEM_METHOD;
  constructor(public id: number) {}
}
export class RemoveProductItemMethod {
  readonly method = DELETE_PRODUCT_ELEMENT_METHOD;
  constructor(public product_id: number) {}
}

export class SetCollectionsToProductMethod {
  readonly method = SET_PRODUCT_TO_COLLECTIONS_METHOD;
  constructor(public product_id: number, public collection_id: number[]) {}
}
